import { Sailing, Route } from "../data";
import { DateTime } from "luxon";

export class FirestorePoller {
  private timeoutId?: number;
  private startMillis: DateTime;

  constructor(
    readonly interval: number,
    readonly route: Route,
    readonly getSailings: (route: Route, past: DateTime) => Promise<Sailing[]>,
    readonly sailingsUpdatedCallback: (sailings: Sailing[]) => any
  ) {
    this.startMillis = DateTime.local().startOf("day");
  }

  public start() {
    this.poll();
    this.schedulePoll();
  }

  public stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  get isStarted() {
    return this.timeoutId !== undefined;
  }

  private async poll() {
    this.getSailings(this.route, this.startMillis).then((sailings) => {
      this.sailingsUpdatedCallback(sailings);
    });
  }

  private schedulePoll() {
    this.timeoutId = window.setTimeout(async () => {
      try {
        this.poll();
        this.schedulePoll();
      } catch (error) {
        console.error("Error getting sailings, will try again", error);
      }
    }, this.interval);
  }
}
