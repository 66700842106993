import { DateTime } from "luxon";
import { CargoView } from ".";

export class ReportRow {
  public static fromArray(row: any[]): ReportRow {
    return new ReportRow(row[0], row[1], row[2], row[3], row[4]);
  }

  public static add(a: ReportRow, b: ReportRow): ReportRow {
    const newCargo: Map<number, number> = new Map();
    Array.from(a.cargoCount).forEach(([key, value]) => {
      if (key === CargoView.CREW) {
        newCargo.set(key, Number.MIN_SAFE_INTEGER);
      } else {
        newCargo.set(key, (newCargo.get(key) ?? 0) + value);
      }
    });
    Array.from(b.cargoCount).forEach(([key, value]) => {
      if (key === CargoView.CREW) {
        newCargo.set(key, Number.MIN_SAFE_INTEGER);
      } else {
        newCargo.set(key, (newCargo.get(key) ?? 0) + value);
      }
    });
    return new ReportRow(
      Number.MIN_SAFE_INTEGER,
      Number.MIN_SAFE_INTEGER,
      a.tripCount + b.tripCount,
      a.overFlowTrips + b.overFlowTrips,
      newCargo
    );
  }

  public static empty() {
    return new ReportRow(0, 0, 0, 0, new Map());
  }

  private constructor(
    readonly scheduled: number,
    readonly destinationPortId: number,
    readonly tripCount: number,
    readonly overFlowTrips: number,
    readonly cargoCount: Map<number, number>
  ) {
    this._trailerTrucks = Array.from(cargoCount)
      .map((value) => {
        const [key, count] = value;
        return CargoView.TRAILER_TRUCK.includes(key) ? count : 0;
      })
      .reduce((acc, curr) => acc + curr, 0);

    this._vehiclesNoTrailers = Array.from(cargoCount)
      .filter((value) => {
        const [key] = value;
        return !CargoView.nonVehicleIds().includes(key);
      })
      .map((value) => value[1])
      .reduce((acc, curr) => acc + curr, 0);
  }

  private _trailerTrucks: number;
  private _vehiclesNoTrailers: number;

  public get trailerTrucks() {
    return this._trailerTrucks;
  }

  public get vehiclesNoTrailers() {
    return this._vehiclesNoTrailers;
  }

  public csvFormat(views: CargoView[]): string[] {
    const cargoArray: string[] = this.buildCargoArray(views);
    return [
      this.scheduled === Number.MIN_SAFE_INTEGER
        ? "n/a"
        : DateTime.fromMillis(this.scheduled).toLocaleString(
            DateTime.DATE_SHORT
          ),
      this.scheduled === Number.MIN_SAFE_INTEGER
        ? "n/a"
        : this.destinationPortId.toString(),
      this.tripCount.toString(),
      ...cargoArray,
      this.overFlowTrips.toString(),
      this.trailerTrucks.toString(),
      this.vehiclesNoTrailers.toString(),
    ];
  }

  private buildCargoArray(views: CargoView[]): string[] {
    const arr: string[] = [];
    views.forEach((_) => arr.push("0"));

    Array.from(this.cargoCount).forEach(([viewId, count]) => {
      const viewIndex = views.findIndex((view) => viewId === view.id);
      arr[viewIndex] = count.toString();
    });
    return arr;
  }
}
