import { DateTime } from "luxon";

export class Time {

    public static fromObject(data: any): Time {
        return new Time(data.hour, data.minute);
    }

    public static fromDateTime(dateTime: DateTime): Time {
        return new Time(dateTime.hour, dateTime.minute);
    }

    constructor(readonly hour: number, readonly minute: number) {
        if (0 > hour && hour > 24) throw new Error('invalid time');
    }

    public toDateTime(): DateTime {
        return DateTime.fromObject({hour: this.hour, minute: this.minute});
    }

    public toObject(): Object {
        return {hour: this.hour ?? null, minute: this.minute ?? null};
    }

    public valueOf(): number {
        return this.hour * 100 + this.minute;
    }
}
