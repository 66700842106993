import * as React from "react";
import { Sailing, Route } from "../data";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Sync, CheckCircleOutline } from "@material-ui/icons";
import { AppUtils } from "../util";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";

const style = (theme: Theme) =>
  createStyles({
    noSailings: {
      padding: theme.spacing(3),
      color: theme.palette.secondary.main,
    },
  });

export interface ISailingsProps extends WithStyles<typeof style> {
  sailings: Sailing[];
  route: Route;
  onEditSailing: (sailing: Sailing) => any;
}

export class Sailings extends React.Component<ISailingsProps, any> {
  public render() {
    return <List>{this.generateSailingsListItems()}</List>;
  }

  private generateSailingsListItems() {
    return this.props.sailings.length > 0 ? (
      this.props.sailings
        .sort((a: Sailing, b: Sailing) => {
          return (
            b.manifest.departure.toMillis() - a.manifest.departure.toMillis()
          );
        })
        .map((sailing, index) => {
          return (
            <ListItem
              button={true}
              key={index}
              disabled={sailing.isDirty()}
              onClick={() => this.handleEditSailingClick(sailing)}
            >
              <ListItemIcon>
                {sailing.isDirty() ? <Sync /> : <CheckCircleOutline />}
              </ListItemIcon>
              <ListItemText
                primary={this.generateSailingSummaryLabel(sailing)}
                secondary={`${this.getPassengerCount(
                  sailing
                )} Passengers and Crew`}
              />
            </ListItem>
          );
        })
    ) : (
      <h4 className={this.props.classes.noSailings}>
        No Sailings Logged in the last 24 hours.
      </h4>
    );
  }

  private generateSailingSummaryLabel = (sailing: Sailing) => {
    const scheduledDeparture = sailing.manifest.isEmergency
      ? "Emergency"
      : sailing.manifest.isShuttling
      ? "Shuttling"
      : AppUtils.formatDateTime(sailing.manifest.scheduledDeparture);
    const confirmed = AppUtils.formatDateTime(sailing.confirmed);
    return `[${scheduledDeparture}] ${sailing.manifest.departurePort.label} (logged: ${confirmed})`;
  };

  private getPassengerCount = (sailing: Sailing) => {
    const passengerCount = sailing.manifest.cargo
      .filter((c) => AppUtils.PassengerIds.includes(c.id))
      .map((c) => c.count);
    passengerCount.push(0);
    return passengerCount.reduce((acc, curr) => {
      return acc + curr;
    });
  };

  private handleEditSailingClick = (sailing: Sailing) => {
    this.props.onEditSailing(sailing);
  };
}

export default withStyles(style)(Sailings);
