import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/functions";

import { AppUtils } from "../util";

const maybeStagingSuffix = AppUtils.useStaging() ? "-staging" : "";

const apiKey = AppUtils.useStaging()
  ? "AIzaSyAozMC21EJRBiNNQ5jW1VqKmp_xJ4OVHE8"
  : "AIzaSyBxLr47S4GmCNrSuuUvXYxNBXjNXgPMQww";

const appId = AppUtils.useStaging()
  ? "1:482021167498:web:1b2d4d9a90baac80d5f873"
  : "1:1058520650388:web:e375c033cf0307cd5f9a81";

const messageSenderId = AppUtils.useStaging()
  ? "482021167498"
  : "1058520650388";

const measurementId = AppUtils.useStaging()
? "G-ET0LNZ7Q64"
: "G-6P4S8BCFEP";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: apiKey,
    authDomain: `drc-bloats${maybeStagingSuffix}.firebaseapp.com`,
    databaseURL: `https://drc-bloats${maybeStagingSuffix}.firebaseio.com`,
    projectId: `drc-bloats${maybeStagingSuffix}`,
    storageBucket: `drc-bloats${maybeStagingSuffix}.appspot.com`,
    messagingSenderId: messageSenderId,
    appId: appId,
    measurementId: measurementId
  });

  firebase.analytics();
  firebase.functions();
}

const auth = firebase.auth;

export default auth;
