import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";

import { TextField, InputAdornment } from "@material-ui/core";
import BloatsIcon from "../BloatsIcon";

const styles = (theme: Theme) =>
  createStyles({
    dense: {
      marginTop: 0,
    },
    labelFocused: {},
  });

interface ICounterProps extends WithStyles<typeof styles> {
  label: string;
  icon: string;
  id: number;
  onChange: (id: number, value: number) => any;
  value?: number;
  required?: boolean;
}

interface ICounterState {
  value?: string;
}

class Counter extends React.Component<ICounterProps, ICounterState> {
  constructor(props: ICounterProps) {
    super(props);
    this.state = { value: props.value ? "" + props.value : "" };
  }

  public render() {
    const classes = this.props.classes;
    return (
      <TextField
        id={this.props.id.toString()}
        label={this.props.label}
        className={classes.dense}
        name={this.props.id.toString()}
        type="number"
        margin="normal"
        variant="outlined"
        onChange={this.handleCountChanged}
        onFocus={this.handleFocus}
        fullWidth={true}
        value={this.state.value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <BloatsIcon name={this.props.icon} />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  private handleFocus = (event: any) => event.target.select();

  private handleCountChanged = (event: any) => {
    const newVal = Number(event.target.value);
    this.setState({ value: newVal === 0 ? "" : event.target.value });
    this.props.onChange(this.props.id, newVal);
  };
}

export default withStyles(styles)(Counter);
