import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { Typography, Grid, Paper } from "@material-ui/core";

import { AppUtils } from "../../util";
import { Manifest, Cargo, CargoView, Route } from "../../data";
import { DateTime } from "luxon";
import BloatsIcon from "../BloatsIcon";

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    detailsGridItem: {
      margin: theme.spacing(),
      padding: theme.spacing(),
      minWidth: "190px",
    },
    detailsGridItemIcon: {
      marginBottom: -theme.spacing(),
      marginRight: theme.spacing(),
      marginTop: -theme.spacing(2),
      marginLeft: theme.spacing(),
      fill: theme.palette.secondary.dark,
      opacity: 0.5,
    },
    error: {
      color: "#cc0000",
      fontWeight: "bold",
    },
    manifestReview: {
      padding: theme.spacing(),
    },
    paper: {
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    tripGrid: {
      margin: theme.spacing(),
      textAlign: "center",
    },
    button: {
      padding: theme.spacing(2),
    },
  });

export interface IManifestConfirmProps extends WithStyles<typeof styles> {
  cargoViews: CargoView[];
  route: Route;
  manifest: Manifest;
  onSubmit?: (confirm: DateTime) => void;
  onBack?: () => void;
}

class ManifestConfirm extends React.Component<IManifestConfirmProps, any> {
  public render() {
    const { classes, manifest } = this.props;
    return (
      <>
        <Paper className={classes.paper}>
          <Grid container justify={"center"} direction={"row"}>
            <Grid item xs={3} sm={3} md={3} className={classes.tripGrid}>
              <Typography component="h1" variant="h6">
                Departure Port
              </Typography>
              <span>{manifest.departurePort.label}</span>
            </Grid>
            {this.renderSailingData(manifest, classes.tripGrid)}
            <Grid
              item
              xs
              className={classNames(
                classes.tripGrid,
                manifest.isScheduleDisabled() && manifest.isSailingDelayed()
                  ? classes.error
                  : ""
              )}
            >
              <Typography component="h1" variant="h6">
                Departure
              </Typography>
              <div>{AppUtils.formatTime(this.props.manifest.departure)}</div>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container className={classes.manifestReview}>
            {this.generateGridItems(
              manifest.cargo,
              classes.detailsGridItem,
              classes.detailsGridItemIcon
            )}
          </Grid>
          <Grid container className={classes.manifestReview}>
            <Grid item>
              Total Vehicles:
              {this.props.manifest.cargo
                .filter(
                  (cargo) =>
                    !CargoView.PASSENGER_AND_OVERFLOW.includes(cargo.id)
                )
                .map((cargo) => cargo.count)
                .concat([0])
                .reduce((prev, curr) => {
                  return prev + curr;
                })}{" "}
            </Grid>
          </Grid>
        </Paper>
        {this.props.onBack || this.props.onSubmit ? (
          <Grid
            container
            direction="row"
            spacing={10}
            alignItems="flex-start"
            justify="space-evenly"
          >
            {this.props.onBack ? (
              <Grid item>
                <Button
                  className={classes.button}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="secondary"
                  tabIndex={3}
                  onClick={() => this.props.onBack!()}
                >
                  Cancel
                </Button>
              </Grid>
            ) : null}
            {this.props.onSubmit ? (
              <Grid item>
                <Button
                  className={classes.button}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  tabIndex={2}
                  onClick={() => this.props.onSubmit!(DateTime.local())}
                >
                  {this.props.manifest.isDeleted ? "Confirm Delete" : "Confirm"}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </>
    );
  }

  private generateGridItems = (
    cargo: Cargo[],
    itemClassName: string,
    iconClassName: string
  ) => {
    const items: JSX.Element[] = [];
    const nonZeroPassengers: Cargo[] = cargo.filter((data) => data.count !== 0);
    for (const data of nonZeroPassengers) {
      items.push(
        <Grid item key={data.id} xs>
          <Paper className={itemClassName} elevation={5}>
            <BloatsIcon name={this.getCargoView(data.id)!.icon} />
            {data.count} {this.getCargoView(data.id)!.label}
          </Paper>
        </Grid>
      );
    }

    return items;
  };

  private getCargoView(id: number): CargoView | undefined {
    return this.props.cargoViews.find((cargoView) => {
      return cargoView.id === id;
    });
  }

  private renderSailingData(manifest: Manifest, name: string) {
    return this.props.manifest.isScheduleDisabled() ? (
      ""
    ) : (
      <Grid item xs className={name}>
        <Typography component="h1" variant="h6">
          Sailing
        </Typography>
        <span>{AppUtils.formatTime(manifest.scheduledDeparture)}</span>
      </Grid>
    );
  }
}

export default withStyles(styles)(ManifestConfirm);
