import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

const styles = (theme: Theme) => createStyles({});

interface IAnnouncementsProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => any;
}

class Announcements extends React.Component<IAnnouncementsProps> {
  public render() {
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Announcements</DialogTitle>
        <DialogContent>
          <DialogContentText>
            * Jan 24: Review sailings will only show last 24 hours, admin
            sailings ordered by scheduled departure (0.15.0)
            <br />
            * Dec 12: Changes - Add version to header for easier debugging
            (0.15.8)
            <br />
            * Nov 28: Changes - Add "Send Diagnostic" button (0.15.7)
            <br />
            * Nov 23: Changes - Emergency and Shuttling do not take up a
            scheduled timeslot, add icons for admin dashboard (0.15.6)
            <br />
            * Nov 11: Changes - Add logging every time a sailing is submitted
            (0.15.5)
            <br />
            * October 31: Changes - Add jiggle to warning, change some copy. Add
            delete sailing feature (0.15.3)
            <br />
            * October 25: Changes - Add warning icon to show missed sailings,
            possible fix missing sailing bug * August 30: Changes - Add icons
            (0.15.0)
            <br />
            * August 17: Changes - UI/icon tweaks, order changes in sailings
            list. sailings review should now include all sailings from start of
            "yesterday". crew is n/a in report. use Leg ID for leg in report,
            change 17:25 to 17:30 sailing (0.14.4)
            <br />
            * August 8: Feature - New Icons! (0.14.3)
            <br />
            * August 8: Change - use 2 column view (0.14.2)
            <br />
            * August 3: Change - crew and supernumary resets after last sailing
            is logged (0.14.1)
            <br />
            * August 3: Change - remove shift, crew and supernumary are now
            remembered items on the manifest (0.14.0)
            <br />
            {/*
             * July 12: Enhancement - add summary rows for report/export (0.13.3)
             * June 22: Enhancement - use manifest confirm in dashboard view, add total (0.13.2)
             * June 22: Fix - dashboard shows proper dates (0.13.1)
             * June 22: Enhancement - remove supernumary from report, add total row (0.13.0)
             * June 6: Fix - proper ordering of legs, fix some date issues around beginning and end of a day (0.12.1)
             * June 6: Enhancement - Waterbridge Report CSV export (0.12.0)
             * June 6: Fix - Fix for blank screen (0.12.0)
             * May 19: Enhancement - Add Waterbridge Report (0.11.0)
             * May 5: Enhancement - Dashboard now allows multiple dates (0.10.0)
             * May 2: Fix - Unscheduled trips will not take up a scheduled time slot. Added unscheduled details to dashboard (0.9.2)
             * April 5: Change - Commercial Semi changed to Commercial (0.9.1)
             * March 30: Bug Fix - Departure Port no longer incorrectly reads "Destination" in Reports (0.9.0)
             * March 22: Enhancement - Actual Departure Time now follows scheduled sailing.
             * March 13: Bug Fix - Trip details data input uses numeric keyboard (0.7.0)
             * March 13: Bug Fix - Fix Actual Departure to reset after logging a sailing (0.6.1)
             * March 11: Bug Fix - Subsequent manifest submissions no longer include last submission data
             * March 11: Improvement - Destination and Schedule update to next logical sailing
             * Feb 25: Improvement - Added Admin dashboard to see last logged sailing and today's sailings.
             */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Got it!</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private handleClose = () => {
    this.props.onClose();
  };
}

export default withStyles(styles)(Announcements);
