import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import NowTimeText from "../NowTimeText";
import { DelayReason, Port, Manifest, Route } from "./../../data";
import { DateTime } from "luxon";
import {
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    gridItem: {
      marginBottom: theme.spacing(2),
    },
    menu: {
      minWidth: 200,
    },
    root: {
      flexGrow: 1,
    },
    title: {
      fontSize: 18,
      marginBottom: theme.spacing(2),
    },
    formHelper: {
      marginBottom: theme.spacing(2),
    },
  });

interface ITripProps extends WithStyles<typeof styles> {
  alreadyLogged: () => boolean;
  canChangeDepartureDetails: () => boolean;
  route: Route;
  manifest: Manifest;

  onDeparturePortChanged: (destination: Port) => any;
  onDepartureChanged: (departure: DateTime) => any;
  onShuttleChanged: (enabled: boolean) => any;
  onEmergencyChanged: (enabled: boolean) => any;
  onScheduledDepartureChanged: (scheduledDeparture: DateTime) => any;
  onDelayReasonChanged: (delayReasonId: number) => any;
}

class Trip extends React.Component<ITripProps, any> {
  public render() {
    const { classes, manifest, route } = this.props;
    const isSailingDelayed = manifest.isSailingDelayed();
    return (
      <form>
        <Typography
          className={classes.title}
          color="textPrimary"
          gutterBottom={true}
        >
          Trip Details
        </Typography>
        <Grid container={true} spacing={1} direction="row" alignItems="stretch">
          <Grid item={true} className={classes.gridItem} xs>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel htmlFor="departing-from">Departing From</InputLabel>
              <Select
                value={manifest.departurePort.id}
                onChange={this.handleDeparturePortChanged}
                disabled={this.props.canChangeDepartureDetails()}
                input={
                  <OutlinedInput
                    name="departing-from"
                    labelWidth={110}
                    id="departing-from"
                  />
                }
                variant="outlined"
              >
                {Array.from(route.ports.values()).map((port: Port) => {
                  return (
                    <MenuItem key={port.id} value={port.id}>
                      {port.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true} xs>
            {manifest.isScheduleDisabled() ? null : (
              <FormControl
                error={
                  this.props.alreadyLogged() &&
                  !this.props.canChangeDepartureDetails()
                }
                variant="outlined"
                fullWidth={true}
                disabled={manifest.isScheduleDisabled()}
                hidden={manifest.isScheduleDisabled()}
              >
                <InputLabel htmlFor="scheduledDeparture">
                  Scheduled Departure
                </InputLabel>
                <Select
                  value={manifest.scheduledDeparture!.toMillis()}
                  input={
                    <OutlinedInput
                      name="scheduledDeparture"
                      labelWidth={130}
                      id="scheduledDeparture"
                    />
                  }
                  variant="outlined"
                  disabled={this.props.canChangeDepartureDetails()}
                  onChange={this.handleScheduledDepartureChanged}
                >
                  {manifest.departurePort.departures
                    .map((time) => {
                      return time.toDateTime();
                    })
                    // .filter((dateTime) => {
                    //   return dateTime.toMillis() >= DateTime.local().toMillis();
                    // })
                    .map(time => {
                      return (
                        <MenuItem
                          key={time.toMillis()}
                          value={time.toMillis()}
                        >
                          {time.toLocaleString(DateTime.TIME_24_SIMPLE)}
                        </MenuItem>
                      );
                    })}
                </Select>
                {this.props.alreadyLogged() &&
                !this.props.canChangeDepartureDetails() ? (
                  <FormHelperText className={classes.formHelper}>
                    Departure time already logged.
                  </FormHelperText>
                ) : null}
              </FormControl>
            )}
          </Grid>
          <Grid item={true} xs>
            {manifest.isScheduleDisabled() || !isSailingDelayed ? null : (
              <FormControl
                variant="outlined"
                fullWidth={true}
                required={true}
                disabled={manifest.isScheduleDisabled() || !isSailingDelayed}
                hidden={manifest.isScheduleDisabled() || !isSailingDelayed}
              >
                <InputLabel htmlFor="delay">Delay Reason</InputLabel>
                <Select
                  value={
                    this.getDelayReason(
                      manifest.delayReasonId ? manifest.delayReasonId! : 1
                    ).id
                  }
                  input={
                    <OutlinedInput name="delay" labelWidth={110} id="delay" />
                  }
                  variant="outlined"
                  onChange={this.handleDelayReasonChanged}
                >
                  {route.delayReasons.map((reason: DelayReason) => {
                    return (
                      <MenuItem key={reason.id} value={reason.id}>
                        {reason.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <Grid item={true}>
              <Grid container={true} direction="column" alignItems="flex-start">
                <Grid item={true}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={manifest.isEmergency}
                        value="isEmergency"
                        onChange={this.handleEmergencyChange}
                        color="primary"
                      />
                    }
                    label="Emergency"
                  />
                </Grid>
                <Grid item={true}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={manifest.isShuttling}
                        value="isShuttleMode"
                        onChange={this.handleShuttleChange}
                        color="secondary"
                      />
                    }
                    label="Shuttle"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} className={classes.gridItem} xs>
            <NowTimeText
              id="actual-departure-time"
              label="Actual Departure"
              value={this.props.manifest.departure}
              onChange={this.handleDepartureChanged}
            />
          </Grid>
        </Grid>
      </form>
    );
  }

  private getDelayReason = (id: number) => {
    return this.props.route.delayReasons.find((reason) => reason.id === id)!;
  };

  private handleShuttleChange = (
    event: React.ChangeEvent,
    checked: boolean
  ) => {
    this.props.onShuttleChanged(checked);
  };

  private handleEmergencyChange = (
    event: React.ChangeEvent,
    checked: boolean
  ) => {
    this.props.onEmergencyChanged(checked);
  };

  private handleDeparturePortChanged = (event: any) => {
    const destinationId = event.target.value;
    const newDestination: Port = this.props.route.ports.find(
      (port) => port.id === destinationId
    )!;
    this.props.onDeparturePortChanged(newDestination);
  };

  private handleDepartureChanged = (event: any) => {
    this.props.onDepartureChanged(DateTime.fromMillis(event));
  };

  private handleScheduledDepartureChanged = (event: any) => {
    const millis = event.target.value;
    this.props.onScheduledDepartureChanged(DateTime.fromMillis(millis));
  };

  private handleDelayReasonChanged = (event: any) => {
    this.props.onDelayReasonChanged(+event.target.value);
  };
}

export default withStyles(styles)(Trip);
