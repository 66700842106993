import { Sailing } from "../data";
import { FirebaseUtils } from "./FirebaseUtils";

export class LocalStoragePoller {
  private timeoutId?: number;

  constructor(
    readonly interval: number,
    readonly getSailings: () => Promise<Sailing[]>,
    readonly sailingsUploadedCallback: (uploadedSailings: Sailing[]) => void
  ) {}

  public start() {
    this.schedulePoll();
  }

  public stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }

  get isStarted() {
    return this.timeoutId !== undefined;
  }

  private schedulePoll() {
    this.timeoutId = window.setTimeout(async () => {
      try {
        if ((await this.poll()) !== false) {
          this.schedulePoll();
        } else {
          this.stop();
        }
      } catch (error) {
        console.error("Error saving application state, will try again", error);
      }
    }, this.interval);
  }

  private async poll(): Promise<boolean> {
    if (!navigator.onLine) {
      return true;
    }

    const sailingsFuture: Promise<Sailing[]> = this.getSailings();

    const sailingsUpsertResult: Promise<Sailing[]> = sailingsFuture.then(
      (sailings) => {
        return this.upsertSailings(sailings);
      }
    );

    return Promise.all([sailingsUpsertResult]).then((_) => {
      return false;
    });
  }

  private async upsertSailings(sailings: Sailing[]): Promise<Sailing[]> {
    const dirtySailings = sailings.filter((sailing) => sailing.isDirty());
    if (dirtySailings.length === 0) {
      return Promise.resolve([]);
    }
    const promises = FirebaseUtils.setSailings(dirtySailings);
    return Promise.all(promises).then((sailings) => {
      this.sailingsUploadedCallback(sailings);
      return sailings;
    });
  }
}
