export class Cargo {
  public static fromObject(data: any): Cargo {
    return new Cargo(data.id, data.count);
  }

  constructor(readonly id: number, readonly count: number) {}

  public toObject(): Object {
    return {
      id: this.id,
      count: this.count,
    };
  }
}
