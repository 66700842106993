import { Cargo } from ".";
import { Port } from "./Port";
import { DateTime } from "luxon";
import { CargoView } from "./CargoView";

export class Manifest {
  public static fromObject(data: any): Manifest {
    return new Manifest(
      data.cargo.map(Cargo.fromObject),
      Port.fromObject(data.destination ?? data.departurePort),
      data.isShuttling,
      data.isEmergency,
      DateTime.fromMillis(data.departure),
      DateTime.fromMillis(data.scheduledDeparture),
      data.delayReasonId,
      data.arrival ? DateTime.fromMillis(data.arrival) : undefined,
      data.deleted ? DateTime.fromMillis(data.deleted) : undefined
    );
  }

  private static readonly nineMinutesish = 540099;

  constructor(
    readonly cargo: Cargo[],
    readonly departurePort: Port,
    readonly isShuttling: boolean,
    readonly isEmergency: boolean,
    readonly departure: DateTime,
    readonly scheduledDeparture?: DateTime,
    readonly delayReasonId?: number,
    readonly arrival?: DateTime,
    readonly deleted?: DateTime
  ) {}

  public isScheduleDisabled(): boolean {
    return this.isEmergency || this.isShuttling;
  }

  public isSailingDelayed(): boolean {
    return this.scheduledDeparture
      ? this.isScheduleDisabled() && this.scheduledDeparture
        ? false
        : this.departure.toMillis() - this.scheduledDeparture.toMillis() >
          Manifest.nineMinutesish
      : false;
  }

  public hasCrew(): boolean {
    return (
      this.cargo.filter((c) => {
        return c.id === CargoView.CREW && c.count > 0;
      }).length > 0
    );
  }

  public get crewCounts(): number[] {
    return this.cargo
      .filter((c) => {
        return c.id === CargoView.CREW || c.id === CargoView.SUPERNUMARY;
      })
      .map((c) => (Number.isNaN(c.count) ? 0 : c.count));
  }

  public get isDeleted(): boolean {
    return typeof this.deleted !== "undefined" && this.deleted !== null;
  }

  public toObject(): Object {
    return {
      cargo: this.cargo.map((c: any) => c.toObject()),
      departurePort: this.departurePort.toObject(),
      isShuttling: this.isShuttling,
      isEmergency: this.isEmergency,
      departure: this.departure.toMillis(),
      scheduledDeparture: this.scheduledDeparture?.toMillis(),
      delayReasonId: this.delayReasonId ?? null,
      arrival: this.arrival ?? null,
      deleted: this.deleted?.toMillis() ?? null
    };
  }
}
