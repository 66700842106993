import { DateTime } from "luxon";
import { Sailing, FlattenedSailing, ReportRow } from ".";
import { CargoView } from "./CargoView";

export class ReportGenerator {
  constructor(
    private readonly collection: firebase.firestore.CollectionReference
  ) {}

  public sailingsToMigrate(from: DateTime, to: DateTime): Promise<Array<Sailing>> {
    return this.collection
      .where("manifest.scheduledDeparture", ">=", from.toMillis())
      .where("manifest.scheduledDeparture", "<=", to.toMillis())
      .orderBy("manifest.scheduledDeparture")
      .get()
      .then((snapshot) => {
        const sailings = snapshot.docs.map(doc => {
          const sailing = Sailing.fromObject(doc.data());
          const dirtySailing = Sailing.dirty(sailing);
          return dirtySailing;
        });
        return sailings;
      });
  }

  public getData(from: DateTime, to: DateTime): Promise<Array<any>> {
    return this.collection
      .where("manifest.scheduledDeparture", ">=", from.toMillis())
      .where("manifest.scheduledDeparture", "<=", to.toMillis())
      .where("manifest.deleted", "==", null)
      .orderBy("manifest.scheduledDeparture")
      .get()
      .then((snapshot) => {
        const flatSailings = snapshot.docs.map((doc) => {
          return new FlattenedSailing(Sailing.fromObject(doc.data()));
        });

        const dateSailings = this.group(
          flatSailings,
          (sailing: FlattenedSailing) => sailing.date.toMillis()
        );
        const dateLegSailings: Array<Array<any>> = Array.from(dateSailings).map(
          ([date, sailingGroup]) => {
            return [
              date,
              this.group(
                sailingGroup,
                (sailing: FlattenedSailing) => sailing.leg
              ),
            ];
          }
        );
        const rows: Array<any> = [];
        dateLegSailings.forEach((tuple) => {
          const [date, legMap] = tuple;
          legMap.forEach((sailings: FlattenedSailing[], legId: number) => {
            const overflowTrips = sailings.filter((sailing) => {
              return sailing.cargoTuples.has(CargoView.OVERFLOW);
            }).length;

            const row = [date, legId, sailings.length, overflowTrips];
            const reportRowMap = new Map<number, number>();
            sailings.forEach((sailing) => {
              Array.from(sailing.cargoTuples).forEach(([cargoId, count]) => {
                if (cargoId !== CargoView.SUPERNUMARY)
                  if (reportRowMap.get(cargoId)) {
                    reportRowMap.set(
                      cargoId,
                      reportRowMap.get(cargoId)! + count
                    );
                  } else {
                    reportRowMap.set(cargoId, count);
                  }
              });
            });
            row.push(reportRowMap);
            rows.push(ReportRow.fromArray(row));
          });
        });

        if (rows.length === 0) {
          return [];
        }

        const totalRow = rows.reduce((acc, curr) => {
          return ReportRow.add(acc, curr);
        }, ReportRow.empty());

        const sortedRows = rows.sort((a: ReportRow, b: ReportRow) => {
          const dateAsc = a.scheduled - b.scheduled;
          return dateAsc + a.destinationPortId - b.destinationPortId;
        });
        sortedRows.push(totalRow);
        return sortedRows;
      });
  }

  public prepareCsv(report: ReportRow[], views: CargoView[]): string {
    const header = [
      [
        "Date",
        "Leg",
        "Trips",
        ...views.map((view) => view.label),
        "Overflow Trips",
        "Trailer Trucks",
        "Vehicles less Trailers",
      ],
    ];

    const result: string[][] = report.map((row) => row.csvFormat(views));

    let csvContent = "data:text/csv;charset=utf-8,";
    header.concat(result).forEach((rowArray) => {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    return csvContent;
  }

  private group<S, T>(list: T[], keyFn: (i: T) => S): Map<S, T[]> {
    const map = new Map<S, T[]>();
    list.forEach((item: T) => {
      const key: S = keyFn(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    return map;
  }
}
