import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router";
import { Route, ReportTypes, AppRoutes } from "../../data";
import { ListItem, ListItemText, List, Paper } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    list: {
      height: "100%",
      position: "relative",
      width: "100%",
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
  });

export interface IReportProps extends WithStyles<typeof styles> {
  routes: Route[];
  history?: any;
}

export interface IReportState {
  route?: Route;
  type?: ReportTypes;
}

class Reports extends React.Component<IReportProps, IReportState> {
  public constructor(props: IReportProps) {
    super(props);
    this.state = {
      route: this.props.routes.length === 1 ? this.props.routes[0] : undefined,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper tabIndex={0} role="button" className={classes.list}>
        <h1>Reports</h1>
        <List>
          <ListItem
            button={true}
            key="Waterbridge Report"
            onClick={() => this.handleClick("waterbridge")}
          >
            <ListItemText primary="Waterbridge Extract Data" />
          </ListItem>
        </List>
      </Paper>
    );
  }
  private handleClick = (reportId: string) => {
    this.props.history.push(
      `${AppRoutes.Reports}${AppRoutes.WaterbridgeReport}`
    );
  };
}

export default withStyles(styles)(withRouter<any, any>(Reports));
