import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      height: "100%",
    },
    progress: {
      margin: theme.spacing(2),
    },
  });

interface ILoadingProps extends WithStyles<typeof styles> {}

export const Loading = withStyles(styles)((props: ILoadingProps) => (
  <Grid
    container
    direction="column"
    spacing={0}
    alignItems="center"
    justify="center"
    className={props.classes.grid}
  >
    <CircularProgress className={props.classes.progress} />
    <Typography>Loading...</Typography>
  </Grid>
));
