import * as React from "react";
import { AppUtils } from "./util";

export interface IServiceWorkerProps {
  onUpdate: (version: string) => any;
  onLoad: (version: string) => any;
}

class ServiceWorker extends React.Component<IServiceWorkerProps, any> {
  public componentDidMount() {
    if (!AppUtils.isLocalhost()) {
      this.register();
    }
  }

  public render() {
    return null;
  }

  private register() {
    if ("serviceWorker" in navigator) {
      const publicUrl = new URL(
        process.env.PUBLIC_URL!,
        window.location.toString()
      );
      if (publicUrl.origin !== window.location.origin) {
        return;
      }

      const self = this;
      window.addEventListener("load", () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker-custom.js`;
        const isDev = AppUtils.isLocalhost();
        if (isDev) {
          this.checkValidServiceWorker(swUrl);

          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation.
          navigator.serviceWorker.ready.then(() => {
            console.log(
              "[ServiceWorker.tsx] This web app is being served cache-first by a service " +
                "worker. To learn more, visit https://goo.gl/SC7cgQ"
            );
          });
        } else {
          // Is not local host. Just register service worker
          this.registerValidSW(swUrl);
        }

        caches.keys().then((keys: string[]) => {
          console.log("cache keys", keys);
          self.props.onLoad(keys[keys.length - 1]);
        });
      });
    }
  }

  private registerValidSW(swUrl: string) {
    console.log("registering", swUrl);
    navigator.serviceWorker
      .register(swUrl, { scope: "." })
      .then((registration) => {
        console.log(`Registration successful with scope ${registration.scope}`);
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            console.log("installing worker.");
            installingWorker.onstatechange = () => {
              console.log(`worker state: ${installingWorker.state}`);
              if (installingWorker.state === "installed") {
                const self = this;
                if (navigator.serviceWorker.controller) {
                  console.log("call update, new content!");
                  caches.keys().then((keys: string[]) => {
                    console.log("cache keys", keys);
                    self.props.onUpdate(keys[keys.length - 1]);
                  });
                } else {
                  caches.keys().then((keys: string[]) => {
                    self.props.onLoad(keys[keys.length - 1]);
                    console.log("cache keys", keys);
                    console.log(`Content is cached for offline use.`);
                  });
                }
              }
            };
          } else {
            console.log("not installing worker...");
          }
        };
      })
      .catch((error) => {
        console.error(`Error during service worker registration`, error);
      });
  }

  private checkValidServiceWorker(swUrl: string) {
    // Check if the service worker can be found. If it can"t reload the page.
    fetch(swUrl)
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        if (
          response.status === 404 ||
          response.headers.get("content-type")!.indexOf("javascript") === -1
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          this.registerValidSW(swUrl);
        }
      })
      .catch(() => {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      });
  }

  private unregister() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    }
  }
}

export default ServiceWorker;
