import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { AppRoutes } from "../data";

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing(),
    },
    dense: {
      marginTop: 16,
    },
    form: {
      marginTop: theme.spacing(),
      width: "100%", // Fix IE 11 issue.
    },
    layout: {
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 400,
      },
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(8),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
    link: {
      color: theme.palette.secondary.main,
    },
  });

interface ISignUpProps extends WithStyles<typeof styles> {
  onSignup: (userName: string, password: string) => void;
}

interface ISignUpState {
  userName?: string;
  password?: string;
  passwordConfirm?: string;
}

class SignUp extends React.Component<ISignUpProps, ISignUpState> {
  constructor(props: ISignUpProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const classes = this.props.classes;

    const isValid =
      !this.state.userName ||
      !this.state.password ||
      !this.state.passwordConfirm ||
      this.state.password !== this.state.passwordConfirm ||
      this.state.userName === "" ||
      this.state.password === "";

    return (
      <form>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <PersonAddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <TextField
              id="email"
              label="Email"
              className={classes.textField}
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              required={true}
              fullWidth={true}
              onChange={this.handleUserNameChanged}
            />
            <TextField
              id="password"
              label="Password"
              className={classes.textField}
              type="password"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              required={true}
              fullWidth={true}
              onChange={this.handlePasswordChanged}
            />
            <TextField
              id="password-confirm"
              label="Confirm Password"
              className={classes.textField}
              type="password"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              required={true}
              fullWidth={true}
              onChange={this.handlePasswordConfirmChanged}
            />
            <Button
              type="submit"
              size="large"
              fullWidth={true}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isValid}
              onClick={this.handleSubmit}
            >
              Sign up
            </Button>
            <br />
            <Link to={AppRoutes.SignIn} className={classes.link}>
              Already have an account? Sign in
            </Link>
          </Paper>
        </main>
      </form>
    );
  }

  private handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.onSignup(this.state.userName!, this.state.password!);
  };

  private handleUserNameChanged = (event: any) => {
    this.setState({
      userName: event.target.value,
    });
  };

  private handlePasswordChanged = (event: any) => {
    this.setState({
      password: event.target.value,
    });
  };

  private handlePasswordConfirmChanged = (event: any) => {
    this.setState({
      passwordConfirm: event.target.value,
    });
  };
}

export default withStyles(styles)(SignUp);
