import { LocalStorage, Sailing } from "../data";

export class LocalStorageUtils {
  public get(key: LocalStorage): any {
    const jsonObject: any = JSON.parse(localStorage.getItem(key)!);
    switch (key) {
      case LocalStorage.Sailings:
        const sailingsArray = (jsonObject ?? []) as any[];
        return sailingsArray.map((x) => Sailing.fromObject(x as Sailing));
      case LocalStorage.Supernumary:
        return Number.parseInt(jsonObject) ?? 0;
      case LocalStorage.Crew:
        return Number.parseInt(jsonObject) ?? 0;
    }
    return undefined;
  }

  public set(key: LocalStorage, data: any) {
    if (data instanceof Array) {
      localStorage.setItem(key, JSON.stringify(data.map((d) => d.toObject())));
    } else if (typeof data === "object") {
      localStorage.setItem(key, JSON.stringify(data.toObject()));
    } else {
      localStorage.setItem(key, data.toString());
    }
  }

  public remove(key: LocalStorage) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear()
  }
}
