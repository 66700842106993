import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import LuxonUtils from "@date-io/luxon";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import IconAlarm from "@material-ui/icons/Alarm";
import { DateTime } from "luxon";

const styles = (theme: Theme) =>
  createStyles({
    clock: {
      flexGrow: 0,
      paddingTop: 3,
    },
    textfield: {
      flexGrow: 2,
      marginRight: theme.spacing(),
    },
    wrapper: {
      display: "flex",
    },
  });

export interface INowTimeNextProps extends WithStyles<typeof styles> {
  id: string;
  label: string;
  value: DateTime;
  onChange: (event: any) => any;
}

export interface INowTimeNextState {
  value: DateTime;
}

class NowTimeText extends React.Component<
  INowTimeNextProps,
  INowTimeNextState
> {
  public render() {
    const classes = this.props.classes;
    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div className={classes.wrapper}>
          <TimePicker
            autoOk={true}
            ampm={false}
            label={this.props.label}
            fullWidth={true}
            value={this.props.value}
            onChange={this.handleTimeChange}
          />
          <div className={classes.clock}>
            <IconButton onClick={this.handleAlarmClick}>
              <IconAlarm />
            </IconButton>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }

  private handleAlarmClick = (event: any) => {
    this.props.onChange(DateTime.local().toMillis());
  };

  private handleTimeChange = (event: any) => {
    this.props.onChange(event.ts);
  };
}

export default withStyles(styles)(NowTimeText);
