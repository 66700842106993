import { Manifest, Route } from ".";
import update from "immutability-helper";
import uuid from "uuid";
import { DateTime } from "luxon";

export class Sailing {
  public static fromObject(data: any): Sailing {
    const manifest = Manifest.fromObject(data.manifest);
    const route = Route.fromObject(data.route);
    return new Sailing(
      route,
      manifest,
      DateTime.fromMillis(data.confirmed),
      data.id
    );
  }

  public static update(sailing: Sailing, data: Object): Sailing {
    const updatedSailing = update(sailing, data);
    return sailing.id
      ? Sailing.dirty(updatedSailing)
      : sailing;
  }

  public static dirty(sailing: Sailing): Sailing {
    return update(sailing, { id: { $set: `dirty-${sailing.id}` } })
  }

  public static clean(sailing: Sailing): Sailing {
    return update(sailing, { id: { $set: sailing.id?.replace("dirty-", "") } });
  }

  public constructor(
    readonly route: Route,
    readonly manifest: Manifest,
    readonly confirmed: DateTime,
    readonly id?: string
  ) {
    this.id = id ?? `dirty-${uuid()}`;
  }

  public isDirty(): boolean {
    return this.id?.includes("dirty") ?? true;
  }

  public toObject(): Object {
    return {
      route: this.route.toObject(),
      manifest: this.manifest.toObject(),
      confirmed: this.confirmed.toMillis(),
      id: this.id,
    };
  }
}
