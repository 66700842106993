import { Roles } from "./Roles";
import { Route } from ".";

export class User {
  public static Unknown: User = new User("", "", Roles.Unknown, []);
  public static Anonymous: User = new User("", "", Roles.Anonymous, []);

  public static buildSuperAdmin(routes: Route[]): User {
    return new User(
      "superadmin",
      "superadmin@raftsbc.com",
      Roles.SuperAdmin,
      routes
    );
  }

  private _route?: Route;
  constructor(
    readonly token: string,
    readonly email: string,
    readonly role: Roles,
    readonly routes: Route[]
  ) {
    this._route = routes[0];
  }

  public get route(): Route {
    return this._route!;
  }

  public get isUnknown(): boolean {
    return this.role === Roles.Unknown;
  }

  public get isAuthenticated(): boolean {
    return this.role !== Roles.Anonymous;
  }

  public get isAdmin(): boolean {
    switch (this.role) {
      case Roles.Admin:
      case Roles.SuperAdmin:
        return true;
      default:
        return false;
    }
  }
}
