import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { DateTime } from "luxon";
import { AppUtils } from "../util";

const styles = (theme: Theme) => createStyles({});

interface IWarningsProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => any;
  missedSailings: DateTime[];
}

class Warnings extends React.Component<IWarningsProps> {
  public render() {
    const { open } = this.props;
    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Missed Sailings</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.generateList()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Okay</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private generateList = () => {
    const items: JSX.Element[] = this.props.missedSailings.map((timeslot) => {
      return (
        <li
          id={timeslot.toMillis().toString()}
          key={timeslot.toMillis().toString()}
        >
          {AppUtils.formatTime(timeslot)}
        </li>
      );
    });

    return <ul>{items}</ul>;
  };

  private handleClose = () => {
    this.props.onClose();
  };
}

export default withStyles(styles)(Warnings);
