import { Sailing } from ".";
import { DateTime } from "luxon";

export class FlattenedSailing {
  constructor(private readonly sailing: Sailing) {}

  public get leg(): number {
    return (
      this.sailing.manifest.departurePort.leg ??
      this.getLegacyLegId(this.sailing.manifest.departurePort.id)
    );
  }

  public get date(): DateTime {
    if (this.sailing.manifest.isScheduleDisabled()) {
      return this.sailing.manifest.departure.startOf("day");
    }
    return this.sailing.manifest.scheduledDeparture!.startOf("day");
  }

  public get cargoTuples(): Map<number, number> {
    const map: Map<number, number> = new Map();
    this.sailing.manifest.cargo.forEach((cargo) => {
      map.set(cargo.id, cargo.count);
    });
    return map;
  }

  private getLegacyLegId(id: number) {
    return id === 2 ? 1 : 2;
  }
}
