import * as React from "react";
import { withRouter } from "react-router";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import update from "immutability-helper";
import Button from "@material-ui/core/Button";
import Trip from "./Trip";
import Cargo from "./Cargo";
import { Grid, Paper } from "@material-ui/core";
import {
  Cargo as CargoDetails,
  Manifest as ManifestDetails,
  Route,
  CargoView,
  Sailing,
  Port,
} from "../../data";
import { DateTime } from "luxon";
import ManifestConfirm from "./ManifestConfirm";

const styles = (theme: Theme) =>
  createStyles({
    submit: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    submitGrid: {
      margin: theme.spacing(),
    },
    paper: {
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    title: {
      fontSize: 18,
    },
  });

interface IManifestProps extends WithStyles<typeof styles> {
  route: Route;
  previousDepartures: DateTime[];
  cargoViews: CargoView[];
  onSubmit: (
    manifest: ManifestDetails,
    confirmed: DateTime,
    sailingId?: string
  ) => void;
  sailingData?: Sailing;
}

interface IManifestState {
  confirming: boolean;
  manifest: ManifestDetails;
}

export class Manifest extends React.Component<IManifestProps, IManifestState> {
  constructor(props: IManifestProps) {
    super(props);
    const now = DateTime.local();
    const port = props.route.getDestinationPortFromTime(now);
    const scheduledDeparture = port.getNextDepartureFromTime(now);
    const stateManifest = this.props.sailingData
      ? this.props.sailingData.manifest
      : new ManifestDetails(
          this.props.cargoViews.map((view) => {
            return new CargoDetails(view.id, view.defaultValue);
          }),
          port,
          false,
          false,
          scheduledDeparture,
          scheduledDeparture
        );
    this.state = { confirming: false, manifest: stateManifest };
  }

  public render() {
    const classes = this.props.classes;

    return this.state.confirming ? (
      <ManifestConfirm
        cargoViews={this.props.cargoViews}
        route={this.props.route}
        manifest={this.state.manifest!}
        onSubmit={this.handleConfirm}
        onBack={this.handleCancel}
      />
    ) : (
      <React.Fragment>
        <Grid container={true} direction="column">
          <Grid item={true}>
            <Paper className={classes.paper} elevation={1}>
              <Trip
                alreadyLogged={this.alreadyLogged}
                canChangeDepartureDetails={this.canChangeDepartureDetails}
                route={this.props.route}
                manifest={this.state.manifest}
                onDeparturePortChanged={this.handleDeparturePortChangedChanged}
                onDepartureChanged={this.handleDepartureChanged}
                onShuttleChanged={this.handleShuttleChanged}
                onEmergencyChanged={this.handleEmergencyChanged}
                onScheduledDepartureChanged={
                  this.handleScheduledDepartureChanged
                }
                onDelayReasonChanged={this.handleDelayReasonChanged}
              />
              <Cargo
                views={this.props.cargoViews}
                onChange={this.handleCargoChanged}
                data={this.state.manifest.cargo}
              />
            </Paper>
          </Grid>
          <Grid item={true} className={classes.submitGrid}>
            <Button
              type="submit"
              size="large"
              disabled={this.isSubmitDisabled()}
              variant="contained"
              color="primary"
              className={classes.submit}
              fullWidth={true}
              onClick={this.handleDeparture}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  private handleCargoChanged = (newCargo: CargoDetails) => {
    const oldManifest = this.state.manifest;
    const updateIndex = oldManifest.cargo.findIndex((detail) => {
      return detail.id === newCargo.id;
    });

    if (updateIndex !== -1) {
      const manifest = update(oldManifest, {
        cargo: { [updateIndex]: { $set: newCargo } },
      });
      this.setState({ manifest });
    } else {
      const manifest = update(oldManifest, {
        cargo: { $push: [newCargo] },
      });
      this.setState({ manifest });
    }
  };

  private handleDeparturePortChangedChanged = (destinationPort: Port) => {
    const nextDeparture = destinationPort.getNextDepartureFromTime(
      DateTime.local()
    );
    const manifest = update(this.state.manifest, {
      departurePort: { $set: destinationPort },
      scheduledDeparture: { $set: nextDeparture },
    });

    this.setState({ manifest });
  };

  private handleDepartureChanged = (departure: DateTime) => {
    const manifest = update(this.state.manifest, {
      departure: { $set: departure },
    });
    this.setState({ manifest });
  };

  private handleShuttleChanged = (isShuttling: boolean) => {
    const manifest = update(this.state.manifest, {
      isShuttling: { $set: isShuttling },
    });
    this.setState({ manifest });
  };

  private handleEmergencyChanged = (isEmergency: boolean) => {
    const manifest = update(this.state.manifest, {
      isEmergency: { $set: isEmergency },
    });
    this.setState({ manifest });
  };

  private handleScheduledDepartureChanged = (scheduleDeparture: DateTime) => {
    const manifest = update(this.state.manifest, {
      scheduledDeparture: { $set: scheduleDeparture },
    });
    this.setState({ manifest });
  };

  private handleDelayReasonChanged = (delayReasonId: number) => {
    const manifest = update(this.state.manifest, {
      delayReasonId: { $set: delayReasonId },
    });
    this.setState({ manifest });
  };

  private handleDeparture = (event: any) => {
    this.setState({ confirming: true });
  };

  private handleConfirm = (confirmed: DateTime) => {
    const manifest = this.state.manifest;
    const nextPort = this.props.route.getNextDestination(
      this.state.manifest.departurePort
    );
    const nextScheduledDeparture = nextPort.getNextDepartureFromTime(
      DateTime.local()
    );

    const newCargo = this.props.cargoViews.map((view) => {
      return view.remember
        ? new CargoDetails(
            view.id,
            this.shouldReset(manifest)
              ? 0
              : manifest.cargo.find((c) => c.id === view.id)?.count ??
                view.defaultValue
          )
        : new CargoDetails(view.id, view.defaultValue);
    });

    this.setState({
      confirming: false,
      manifest: update(manifest, {
        departurePort: { $set: nextPort },
        cargo: { $set: newCargo },
        departure: { $set: nextScheduledDeparture },
        scheduledDeparture: { $set: nextScheduledDeparture },
      }),
    });

    this.props.onSubmit(manifest, confirmed, this.props.sailingData?.id);
  };

  private shouldReset(manifest: ManifestDetails): boolean {
    if (manifest.isScheduleDisabled()) {
      return false;
    } else {
      const departureIndex = manifest.departurePort.departures.findIndex(
        (departure) => {
          return (
            departure.toDateTime().toMillis() ===
            manifest.scheduledDeparture!.toMillis()
          );
        }
      );
      const resetIndex = manifest.departurePort.rememberedCargoResetIndex;
      return resetIndex === departureIndex;
    }
    
  }

  private handleCancel = () => {
    this.setState({ confirming: false });
  };

  private isSubmitDisabled = () => {
    const alreadyLogged = this.alreadyLogged();
    const canChangeDepartureDetails = this.canChangeDepartureDetails();
    const isScheduleDisabled = this.isScheduleDisabled();
    const hasCrew = this.hasCrew();

    return (
      (alreadyLogged && !canChangeDepartureDetails && !isScheduleDisabled) ||
      !hasCrew
    );
  };

  private alreadyLogged = (): boolean => {
    if (this.state.manifest.isScheduleDisabled()) {
      return false;
    } else {
        const scheduledDepartureMs = this.state.manifest.scheduledDeparture!.toMillis();
        return this.props.previousDepartures
          .map((departure) => departure.toMillis())
          .includes(scheduledDepartureMs);
    }
  };

  private canChangeDepartureDetails = () => {
    return this.props.sailingData !== undefined;
  };

  private isScheduleDisabled = () => {
    return this.state.manifest.isScheduleDisabled();
  };

  private hasCrew = () => {
    return this.state.manifest.hasCrew();
  };
}

export default withStyles(styles)(withRouter<any, any>(Manifest));
