import * as React from "react";
import { withRouter } from "react-router";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import update from "immutability-helper";
import Button from "@material-ui/core/Button";
import Trip from "./Trip";
import Cargo from "./Cargo";
import { Grid, Paper } from "@material-ui/core";
import {
  Cargo as CargoDetails,
  Manifest as ManifestDetails,
  Route,
  CargoView,
  Sailing,
  Port,
} from "../../data";
import { DateTime } from "luxon";
import ManifestConfirm from "./ManifestConfirm";
import { DeleteRounded } from "@material-ui/icons";

const styles = (theme: Theme) =>
  createStyles({
    submit: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(6),
      marginLeft: theme.spacing(2)
    },
    delete: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(6),
      marginRight: theme.spacing(2)
    },
    submitGrid: {
      margin: theme.spacing(),
    },
    paper: {
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    title: {
      fontSize: 18,
    },
  });

interface IManifestEditProps extends WithStyles<typeof styles> {
  route: Route;
  previousDepartures: DateTime[];
  cargoViews: CargoView[];
  onSubmit: (
    manifest: ManifestDetails,
    arrival: DateTime,
    sailingId?: string
  ) => void;
  sailingData?: Sailing;
}

interface IManifestEditState {
  confirming: boolean;
  manifest: ManifestDetails;
}

export class ManifestEdit extends React.Component<
  IManifestEditProps,
  IManifestEditState
> {
  constructor(props: IManifestEditProps) {
    super(props);
    const now = DateTime.local();
    const port = props.route.getDestinationPortFromTime(now);
    const scheduledDeparture = port.getNextDepartureFromTime(now);
    const stateManifest = this.props.sailingData
      ? this.props.sailingData.manifest
      : new ManifestDetails(
          this.props.cargoViews.map((view) => {
            return new CargoDetails(view.id, 0);
          }),
          port,
          false,
          false,
          now,
          scheduledDeparture
        );
    this.state = { confirming: false, manifest: stateManifest };
  }

  public render() {
    const classes = this.props.classes;

    return this.state.confirming ? (
      <ManifestConfirm
        cargoViews={this.props.cargoViews}
        route={this.props.route}
        manifest={this.state.manifest!}
        onSubmit={this.handleConfirm}
        onBack={this.handleCancel}
      />
    ) : (
      <React.Fragment>
        <Grid container={true} direction="column">
          <Grid item={true}>
            <Paper className={classes.paper} elevation={1}>
              <Trip
                alreadyLogged={this.alreadyLogged}
                canChangeDepartureDetails={this.canChangeDepartureDetails}
                route={this.props.route}
                manifest={this.state.manifest}
                onDeparturePortChanged={this.handleDeparturePortChanged}
                onDepartureChanged={this.handleDepartureChanged}
                onShuttleChanged={this.handleShuttleChanged}
                onEmergencyChanged={this.handleEmergencyChanged}
                onScheduledDepartureChanged={
                  this.handleScheduledDepartureChanged
                }
                onDelayReasonChanged={this.handleDelayReasonChanged}
              />
              <Cargo
                views={this.props.cargoViews}
                onChange={this.handleCargoChanged}
                data={this.state.manifest.cargo}
              />
            </Paper>
          </Grid>
          <Grid container={true} direction="row" className={classes.submitGrid}>
            <Grid item={true} xs={3}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="secondary"
                className={classes.delete}
                fullWidth={true}
                onClick={this.handleDelete}
              >
                <DeleteRounded />
                Delete
              </Button>
            </Grid>
            <Grid item={true} xs={9}>
              <Button
                type="submit"
                size="large"
                disabled={
                  this.alreadyLogged() && !this.canChangeDepartureDetails()
                }
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.handleDeparture}
                fullWidth={true}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  private handleCargoChanged = (newCargo: CargoDetails) => {
    const oldManifest = this.state.manifest;
    const updateIndex = oldManifest.cargo.findIndex((detail) => {
      return detail.id === newCargo.id;
    });

    if (updateIndex !== -1) {
      const manifest = update(oldManifest, {
        cargo: { [updateIndex]: { $set: newCargo } },
      });
      this.setState({ manifest });
    } else {
      const manifest = update(oldManifest, {
        cargo: { $push: [newCargo] },
      });
      this.setState({ manifest });
    }
  };

  private handleDeparturePortChanged = (destinationPort: Port) => {
    const nextDeparture = destinationPort.getNextDepartureFromTime(
      DateTime.local()
    );
    const manifest = update(this.state.manifest, {
      departurePort: { $set: destinationPort },
      scheduledDeparture: { $set: nextDeparture },
    });

    this.setState({ manifest });
  };

  private handleDepartureChanged = (departure: DateTime) => {
    const manifest = update(this.state.manifest, {
      departure: { $set: departure },
    });
    this.setState({ manifest });
  };

  private handleShuttleChanged = (isShuttling: boolean) => {
    const manifest = update(this.state.manifest, {
      isShuttling: { $set: isShuttling },
    });
    this.setState({ manifest });
  };

  private handleEmergencyChanged = (isEmergency: boolean) => {
    const manifest = update(this.state.manifest, {
      isEmergency: { $set: isEmergency },
    });
    this.setState({ manifest });
  };

  private handleScheduledDepartureChanged = (scheduleDeparture: DateTime) => {
    const manifest = update(this.state.manifest, {
      scheduledDeparture: { $set: scheduleDeparture },
    });
    this.setState({ manifest });
  };

  private handleDelayReasonChanged = (delayReasonId: number) => {
    const manifest = update(this.state.manifest, {
      delayReasonId: { $set: delayReasonId },
    });
    this.setState({ manifest });
  };

  private handleDeparture = (event: any) => {
    this.setState({ confirming: true });
  };

  private handleDelete = (event: any) => {
    this.setState({
      manifest: update(this.state.manifest, {
        deleted: { $set: DateTime.local() },
      }),
    });
    this.setState({ confirming: true });
  };

  private handleConfirm = (confirmed: DateTime) => {
    const nextPort = this.props.route.getNextDestination(
      this.state.manifest.departurePort
    );
    const nextScheduledDeparture = nextPort.getNextDepartureFromTime(
      DateTime.local()
    );

    this.setState({
      confirming: false,
      manifest: update(this.state.manifest, {
        departurePort: { $set: nextPort },
        scheduledDeparture: { $set: nextScheduledDeparture },
      }),
    });

    this.props.onSubmit(
      this.state.manifest,
      confirmed,
      this.props.sailingData?.id
    );
  };

  private handleCancel = () => {
    this.setState({ confirming: false });
  };

  private alreadyLogged = (): boolean => {
    if (this.state.manifest.isScheduleDisabled()) {
      return false;
    } else {
      const scheduledDepartureMs = this.state.manifest.scheduledDeparture!.toMillis();
      return this.props.previousDepartures
        .map((departure) => departure.toMillis())
        .includes(scheduledDepartureMs);
    }
  };

  private canChangeDepartureDetails = () => {
    return this.props.sailingData !== undefined;
  };
}

export default withStyles(styles)(withRouter<any, any>(ManifestEdit));
