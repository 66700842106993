import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import { Grid } from "@material-ui/core";
import Counter from "./Counter";
import { Cargo as CargoDetails, CargoView } from "../../data";

const styles = (theme: Theme) =>
  createStyles({
    counter: {
      paddingRight: theme.spacing(),
    },
  });

interface ICargoProps extends WithStyles<typeof styles> {
  views: CargoView[];
  onChange: (changed: CargoDetails) => any;
  data?: CargoDetails[];
}

class Cargo extends React.Component<ICargoProps> {
  public render() {
    return <form>{this.generateList()}</form>;
  }

  private handleCounterChanged = (index: number) => (
    id: number,
    amount: number
  ) => {
    this.props.onChange(new CargoDetails(id, amount));
  };

  private generateList = () => {
    const gridItems: JSX.Element[] = this.props.views.map((view, index) => {
      const value: number | undefined =
        this.props.data?.find((cargo) => {
          return cargo.id === view.id;
        })?.count ?? view.defaultValue;

      return (
        <Grid item key={index} xs={6} className={this.props.classes.counter}>
          <Counter
            label={view.label}
            id={view.id}
            key={index}
            icon={view.icon}
            onChange={this.handleCounterChanged(index)}
            value={value}
          />
        </Grid>
      );
    });

    return (
      <Grid container alignItems="stretch" direction="row">
        {gridItems}
      </Grid>
    );
  };
}

export default withStyles(styles)(Cargo);
