import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { ReactComponent as SemiOver24 } from "../icons/SemiOver24.svg";
import { ReactComponent as RvOver6 } from "../icons/RvOver6.svg";
import { ReactComponent as RvUnder6 } from "../icons/RvUnder6.svg";
import { ReactComponent as LoadedLogging } from "../icons/LoadedLogging.svg";
import { ReactComponent as LoadedLumber } from "../icons/LoadedLumber.svg";
import { ReactComponent as ChipTruck } from "../icons/ChipTruck.svg";
import { Icon } from "@mdi/react";

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      fill: theme.palette.secondary.dark,
      opacity: 0.75,
    },
  });

interface IBloatsIcon extends WithStyles<typeof styles> {
  name: string;
}

class BloatsIcon extends React.Component<IBloatsIcon> {
  public static readonly SEMI_OVER_24 = "SemiOver24";

  public render() {
    const classes = this.props.classes;
    return (
      this.getBloatsIcon(this.props.name, classes.icon) ?? (
        <Icon
          path={this.props.name}
          size={1}
          className={this.props.classes.icon}
        />
      )
    );
  }

  private getBloatsIcon(
    name: string,
    className: string
  ): JSX.Element | undefined {
    switch (name) {
      case "SemiOver24":
        return <SemiOver24 className={className} />;
      case "RvOver6":
        return <RvOver6 className={className} />;
      case "RvUnder6":
        return <RvUnder6 className={className} />;
      case "LoadedLogging":
        return <LoadedLogging className={className} />;
      case "LoadedLumber":
        return <LoadedLumber className={className} />;
      case "ChipTruck":
        return <ChipTruck className={className} />;
      default:
        return undefined;
    }
  }
}

export default withStyles(styles)(BloatsIcon);
