import { DateTime } from "luxon";
import { Time } from "./Time";

export class Port {
  public static Empty = new Port(-1, "", [], -1);

  public static fromObject(jsonPortObject: any): Port {
    return new Port(
      jsonPortObject.id,
      jsonPortObject.label,
      jsonPortObject.departures.map((departure: any) =>
        Time.fromObject(departure)
      ),
      jsonPortObject.leg
    );
  }

  constructor(
    readonly id: number,
    readonly label: string,
    readonly departures: Time[],
    readonly leg: number,
    readonly rememberedCargoResetIndex?: number
  ) {}

  public departuresOnDemand = this.departures === undefined;

  public getNextDepartureFromTime(dateTime: DateTime): DateTime {
    const time = Time.fromDateTime(dateTime);
    const departureIndex = this.departures.findIndex((departure) => {
      return departure > time;
    });
    return departureIndex >= 0
      ? this.departures[departureIndex].toDateTime()
      : this.departures[0].toDateTime();
  }

  public toObject(): Object {
    return {
      id: this.id,
      label: this.label,
      departures: this.departures.map((departure) => departure.toObject()),
    };
  }
}
