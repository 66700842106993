import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { orange } from "@material-ui/core/colors";

import * as React from "react";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#4C6995" },
    secondary: orange,
  },
});

function withRoot<P>(
  Component: React.ComponentType<P>
): (props: P) => JSX.Element {
  function WithRoot(props: P): JSX.Element {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
