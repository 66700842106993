import * as React from "react";
import { createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  ExitToAppTwoTone,
  WbSunnyTwoTone,
  DirectionsBoatTwoTone,
  PersonAdd,
} from "@material-ui/icons";
import { AppRoutes } from "../data";
import packageJson from "../../package.json";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      bottom: 0,
      position: "absolute",
      width: 250,
    },
    list: {
      height: "100%",
      position: "relative",
      width: 250,
    },
    smallIcon: {
      paddingTop: 4,
      fontSize: 15,
    },
    loading: {
      paddingRight: theme.spacing(),
    },
  });

interface IMenuDrawerProps extends WithStyles<typeof styles> {
  open: boolean;
  history?: any; // react's withRouter needs this as a type hint
  onDismiss: () => any;
  onSignOut: () => Promise<void>;
  onUploadState: () => Promise<any>;
}

interface IMenuDrawerState {
  loading: boolean;
}

export class MenuDrawer extends React.Component<
  IMenuDrawerProps,
  IMenuDrawerState
> {
  constructor(props: IMenuDrawerProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    const { classes } = this.props;
    const maybeLoadingIcon = this.state.loading ? (
      <CircularProgress size={10}  />
    ) : null;
    return (
      <Drawer
        anchor="right"
        open={this.props.open}
        onClose={() => this.props.onDismiss()}
      >
        <div tabIndex={0} role="button" className={classes.list}>
          <List>
            <ListItem
              button={true}
              key="Log Sailing"
              onClick={this.handleManifestClick}
            >
              <ListItemIcon>
                <PersonAdd />
              </ListItemIcon>
              <ListItemText primary="Log Sailing" />
            </ListItem>
            <ListItem
              button={true}
              key="Review Sailings"
              onClick={this.handleSailingsClick}
            >
              <ListItemIcon>
                <DirectionsBoatTwoTone />
              </ListItemIcon>
              <ListItemText primary="Review Sailings" />
            </ListItem>
            <ListItem button={true} key="Log Weather" disabled={true}>
              <ListItemIcon>
                <WbSunnyTwoTone />
              </ListItemIcon>
              <ListItemText primary="Log Weather" />
            </ListItem>
          </List>
          <div className={classes.footer}>
            <List>
              <ListItem
                button={true}
                key="Sign Out"
                onClick={this.handleUploadState}
              >
                <span className={classes.loading}>Send Diagnostics</span>{maybeLoadingIcon}
              </ListItem>
              <ListItem>Version: {packageJson.version}</ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                button={true}
                key="Sign Out"
                onClick={this.handleSignOut}
              >
                <ListItemIcon>
                  <ExitToAppTwoTone />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    );
  }

  private handleUploadState = () => {
    this.setState({ loading: true });
    this.props
      .onUploadState()
      .then((_) => {
        this.setState({ loading: false });
        console.log("finished, stop the busy animation");
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("couldn't log state");
      });
  };

  private handleSignOut = () => {
    this.props.onSignOut();
    this.props.onDismiss();
  };

  private handleSailingsClick = () => {
    this.props.history.push(AppRoutes.Sailings);
    this.props.onDismiss();
  };

  private handleManifestClick = () => {
    this.props.history.push(AppRoutes.Manifest);
    this.props.onDismiss();
  };
}

export default withStyles(styles)(withRouter<any, any>(MenuDrawer));
