export enum AppRoutes {
  SignUp = "/signup",
  SignIn = "/signin",
  SignOut = "/signout",
  Manifest = "/manifest",
  Sailings = "/sailings",
  Root = "/",
  Admin = "/admin",
  Reports = "/reports",
  WildcardId = "/:id",
  WaterbridgeReport = "/waterbridge",
}
