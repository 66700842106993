import * as React from "react";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import packageJson from "../../package.json";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import MenuDrawer from "./MenuDrawer";
import { User } from "../data";
import MenuDrawerAdmin from "./MenuDrawerAdmin";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import WarningIcon from "@material-ui/icons/Warning";
import Announcements from "./Announcements";
import { AppUtils } from "../util";
import { DateTime } from "luxon";
import Warnings from "./Warnings";

const styles = (theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 10,
    },
    root: {
      flexGrow: 1,
    },
    title: {
      marginLeft: 10,
    },
    announcements: {
      color: theme.palette.info.light,
    },
    warnings: {
      color: theme.palette.warning.light,
      animation: "shake 1.64s infinite both",
    },
    warnings_hidden: {
      hidden: true,
    },
    small: {
      fontSize: 8,
      paddingLeft: theme.spacing(1),
    },
  });

interface INavBarProps extends WithStyles<typeof styles> {
  user: User;
  version: string;
  onSignOut: () => void;
  onUploadState: () => Promise<any>;
  missedSailings: DateTime[];
}

export class NavBar extends React.PureComponent<INavBarProps, any> {
  constructor(props: INavBarProps) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      showAnnouncements: false,
      showWarnings: false,
    };
  }

  public render() {
    const classes = this.props.classes;
    return (
      <React.Fragment>
        <AppBar position="sticky">
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              className={classNames(classes.grow, classes.title)}
            >
              R.A.F.T.S.
              <span className={classes.small}>
                {packageJson.version}
                {AppUtils.useStaging() ? " staging" : ""}
              </span>
            </Typography>
            <IconButton
              onClick={this.handleWarningsClicked}
              className={
                this.props.missedSailings.length > 0
                  ? classes.warnings
                  : classes.warnings_hidden
              }
            >
              <WarningIcon />
            </IconButton>
            <IconButton onClick={this.handleAnnouncentsClicked}>
              <NewReleasesIcon className={classes.announcements} />
            </IconButton>

            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              disabled={this.isMenuDisabled()}
              onClick={this.handleMenuClicked}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {this.getMenuDrawer()}
        <Announcements
          open={this.state.showAnnouncements}
          onClose={this.handleCloseAnnouncements}
        />
        <Warnings
          missedSailings={this.props.missedSailings}
          open={this.state.showWarnings}
          onClose={this.handleCloseWarnings}
        />
      </React.Fragment>
    );
  }

  private isMenuDisabled(): boolean {
    const user = this.props.user;
    return !user.isAuthenticated;
  }

  private handleMenuClicked = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  private handleUploadState = () => {
    return this.props.onUploadState();
  };

  private handleSignOut = () => {
    this.props.onSignOut();
  };

  private handleWarningsClicked = () => {
    this.setState({ showWarnings: true });
  };

  private handleCloseWarnings = () => {
    this.setState({ showWarnings: false });
  };

  private handleAnnouncentsClicked = () => {
    this.setState({ showAnnouncements: true });
  };

  private handleCloseAnnouncements = () => {
    this.setState({ showAnnouncements: false });
  };

  private getMenuDrawer = () => {
    if (this.props.user.isAdmin) {
      return (
        <MenuDrawerAdmin
          open={this.state.isDrawerOpen}
          onDismiss={this.handleMenuClicked}
          onSignOut={this.handleSignOut}
        />
      );
    }

    return (
      <MenuDrawer
        open={this.state.isDrawerOpen}
        onDismiss={this.handleMenuClicked}
        onSignOut={this.handleSignOut}
        onUploadState={this.handleUploadState}
      />
    );
  };
}

export default withStyles(styles)(NavBar);
