import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";
import { Grid, Button } from "@material-ui/core";

const styles = (_theme: Theme) => createStyles({});

export interface IStartEndDatePickerProps extends WithStyles<typeof styles> {
  onDateRangeSubmit: (start: DateTime, end: DateTime) => void;
  singleDate?: boolean;
  start?: DateTime;
  end?: DateTime;
}

export interface IStartEndDatePickerState {
  start: DateTime;
  end: DateTime;
}

class StartEndDatPicker extends React.Component<
  IStartEndDatePickerProps,
  IStartEndDatePickerState
> {
  public constructor(props: IStartEndDatePickerProps) {
    super(props);
    this.state = {
      start: props.start ?? DateTime.local().startOf("day"),
      end: props.end ?? DateTime.local().endOf("day"),
    };
  }

  render() {
    const dates = this.props.singleDate ? (
      <Grid item={true}>
        <DatePicker
          autoOk
          label="Date"
          value={this.state.start}
          onChange={(event) => this.setState({ start: event!, end: event! })}
        />
      </Grid>
    ) : (
      <>
        <Grid item={true}>
          <DatePicker
            autoOk
            label="Start"
            value={this.state.start}
            onChange={(event) => this.setState({ start: event! })}
          />
        </Grid>
        <Grid item={true}>
          <DatePicker
            autoOk
            label="End"
            value={this.state.end}
            onChange={(event) => this.setState({ end: event! })}
          />
        </Grid>
      </>
    );

    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Grid
          container={true}
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          {dates}

          <Grid item={true}>
            <Button
              onClick={() =>
                this.props.onDateRangeSubmit(this.state.start, this.state.end)
              }
              variant="contained"
              color="secondary"
            >
              Go
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(StartEndDatPicker);
