export class CargoView {
  public static readonly SUPERNUMARY = 15;
  public static readonly PRA = 13;
  public static readonly PASSENGER = 1;
  public static readonly OVERFLOW = 14;
  public static readonly CHIP_TRUCK = 5;
  public static readonly LOGGING_TRUCK = 6;
  public static readonly LUMBER_TRUCK = 7;
  public static readonly SEMI_OVER_24 = 16;
  public static readonly TRAILER = 11;
  public static readonly CREW = 17;

  public static readonly PASSENGER_AND_OVERFLOW = [
    CargoView.SUPERNUMARY,
    CargoView.PRA,
    CargoView.PASSENGER,
    CargoView.OVERFLOW,
    CargoView.CREW,
  ];

  public static readonly TRAILER_TRUCK = [
    CargoView.CHIP_TRUCK,
    CargoView.LOGGING_TRUCK,
    CargoView.LUMBER_TRUCK,
    CargoView.SEMI_OVER_24,
  ];

  constructor(
    readonly id: number,
    readonly label: string,
    readonly icon: string,
    readonly defaultValue: number,
    readonly order: number,
    readonly remember: boolean = false
  ) {}

  public static nonVehicleIds(): number[] {
    const ids = CargoView.PASSENGER_AND_OVERFLOW;
    ids.push(CargoView.TRAILER);
    return ids;
  }

  public static crewIds(): number[] {
    return [CargoView.SUPERNUMARY, CargoView.CREW];
  }
}
