import * as React from "react";
import { Sailing, CargoView } from "../data";
import {
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Theme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { AppUtils } from "../util";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { DateTime } from "luxon";
import { Loading } from "./Loading";
import StartEndDatePicker from "./StartEndDatePicker";
import ManifestConfirm from "./manifest/ManifestConfirm";
import { LocalTaxi, SwapHoriz } from "@material-ui/icons";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(),
      padding: theme.spacing(),
    },
    sailingsGrid: {
      marginTop: theme.spacing(2),
    },
    sailingRow: {
      cursor: "pointer",
    },
  });

interface IDashboardProps extends WithStyles<typeof styles> {
  sailings: Sailing[];
  onDateChange: (start: DateTime, end: DateTime) => void;
  loading: boolean;
  cargoViews: CargoView[];
}

interface IDashboardState {
  start: DateTime;
  open: boolean;
  sailing?: Sailing;
}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
  public constructor(props: IDashboardProps) {
    super(props);
    this.state = {
      open: false,
      start: DateTime.local().startOf("day"),
    };
  }

  render() {
    const classes = this.props.classes;
    const { sailings } = this.props;
    const sailingsSorted = sailings.sort(AppUtils.sailingSort).reverse();
    const sailing = sailingsSorted.length > 0 ? sailingsSorted[0] : undefined;

    return (
      <div className={classes.paper}>
        <Paper className={classes.paper} elevation={3}>
          <StartEndDatePicker
            start={this.state.start}
            singleDate={true}
            onDateRangeSubmit={this.handleDateChange}
          />
        </Paper>
        {this.props.loading ? (
          <Loading />
        ) : (
          <>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              alignItems="flex-start"
              className={classes.sailingsGrid}
            >
              <Grid item={true} xs>
                <Typography>
                  Latest Sailing{" "}
                  {` ${DateTime.local().toLocaleString(DateTime.DATE_SHORT)}`}
                </Typography>
                {sailing ? (
                  this.renderCurrentSailingTable(sailing)
                ) : (
                  <>No sailings logged today</>
                )}
              </Grid>
              <Grid item={true} xs>
                <Typography>
                  Sailings for
                  {` ${this.state.start.toLocaleString(DateTime.DATE_SHORT)} `}
                </Typography>
                {this.renderPreviousSailings(sailingsSorted)}
              </Grid>
            </Grid>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Sailing Details</DialogTitle>
              <DialogContent>
                {!this.state.sailing
                  ? null
                  : this.renderCurrentSailingTable(this.state.sailing)}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>OK</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    );
  }

  private handleDateChange = (start: DateTime, end: DateTime) => {
    this.props.onDateChange(start, end);
    this.setState({ start: start });
  };
  private renderCurrentSailingTable(sailing: Sailing) {
    return (
      <ManifestConfirm
        cargoViews={this.props.cargoViews}
        route={sailing.route}
        manifest={sailing.manifest}
      />
    );
  }

  private renderPreviousSailings(sailings: Sailing[]) {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="Today's Sailings">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Departure</TableCell>
              <TableCell>Scheduled Departure</TableCell>
              <TableCell>Actual Departure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.createRows(sailings)}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  private createRows = (sailings: Sailing[]) => {
    const rows = sailings.map((sailing) => {

      const scheduledDeparture = sailing.manifest.isEmergency ? (
        <LocalTaxi />
      ) : sailing.manifest.isShuttling ? (
        <SwapHoriz />
      ) : (
        AppUtils.formatDateTime(sailing.manifest.scheduledDeparture)
      );

      return (
        <TableRow
          key={sailing.id!}
          onClick={() => this.handleSailingClick(sailing)}
          className={this.props.classes.sailingRow}
        >
          <TableCell>
            {sailing.manifest.departure.toLocaleString(
              DateTime.DATE_SHORT
            )}
          </TableCell>
          <TableCell>{sailing.manifest.departurePort.label}</TableCell>
          <TableCell align="center">{scheduledDeparture}</TableCell>
          <TableCell>
            {AppUtils.formatTime(sailing.manifest.departure)}
          </TableCell>
        </TableRow>
      );
    });

    return <>{rows}</>;
  };

  private handleSailingClick = (sailing: Sailing) => {
    this.setState({ open: true, sailing: sailing });
  };

  private handleClose = (event: any) => {
    this.setState({ open: false, sailing: undefined });
  };
}

export default withStyles(styles)(Dashboard);
