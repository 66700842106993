import auth from "./../firebase/config";
import { Route, Sailing } from "../data";
import * as firebase from "firebase/app";
import { DateTime } from "luxon";
import "firebase/firestore";
import { SailingDateType } from "./SailingDateType";

export class FirebaseUtils {
  private static sailingsCollectionName: string = "sailings";
  private static usersCollectionName: string = "users";

  public static createUser = (email: string, password: string) =>
    auth()
      .setPersistence(auth.Auth.Persistence.LOCAL)
      .then((_) => auth().createUserWithEmailAndPassword(email, password))
      .catch((error) => Promise.reject(error));

  public static signIn = (email: string, password: string) =>
    auth()
      .setPersistence(auth.Auth.Persistence.LOCAL)
      .then((_) => auth().signInWithEmailAndPassword(email, password))
      .catch((error) => Promise.reject(error));

  public static signOut = () => auth().signOut();

  public static sendPasswordResetEmail = (email: string) =>
    auth().sendPasswordResetEmail(email);

  public static updatePassword = async (password: string) => {
    const currentUser = auth().currentUser;
    if (currentUser) {
      await currentUser.updatePassword(password);
    }
    throw Error("no authenticated user");
  };

  public static getPreviousSailings = (route: Route): Promise<Sailing[]> => {
    return FirebaseUtils.getLastSailings(
      route.id,
      SailingDateType.Scheduled,
      route.departureCount
    );
  };

  public static getSailings = (
    routeId: number,
    start: DateTime,
    end: DateTime,
    dateType: SailingDateType,
    limit?: number
  ): Promise<Sailing[]> => {
    return FirebaseUtils.sailingsQuery(routeId, start, end, dateType, limit)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => Sailing.fromObject(doc.data()));
      });
  };

  public static getLastSailings = (
    routeId: number,
    dateType: SailingDateType,
    limit: number
  ) => {
    return FirebaseUtils.lastSailingsQuery(routeId, dateType, limit)
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((doc) => Sailing.fromObject(doc.data()));
      });
  }

  private static lastSailingsQuery = (
    routeId: number,
    dateType: SailingDateType,
    limit: number
  ) => {
    const sailingsStore = firebase
      .firestore()
      .collection(FirebaseUtils.sailingsCollectionName)
    const query = sailingsStore
      .where("manifest.deleted", "==", null)
      .where("route.id", "==", routeId)
      .where(dateType, ">=", DateTime.local().minus({days: 1}).toMillis())
      .orderBy(dateType, "desc")
      .limit(limit);
    return query;
  }

  private static sailingsQuery = (
    routeId: number,
    start: DateTime,
    end: DateTime,
    dateType: SailingDateType,
    limit?: number
  ) => {
    const sailingsStore = firebase
      .firestore()
      .collection(FirebaseUtils.sailingsCollectionName);
    const query = sailingsStore
      .where("route.id", "==", routeId)
      .where("manifest.deleted", "==", null)
      .where(dateType, ">=", start.toMillis())
      .where(dateType, "<=", end.toMillis())
      .orderBy(dateType, "desc");

    if (limit) {
      query.limit(limit);
    }
    return query;
  };

  public static setSailings(sailings: Sailing[]): Promise<Sailing>[] {
    return sailings.map((sailing) => {
      const sailingsCollection = firebase
        .firestore()
        .collection(FirebaseUtils.sailingsCollectionName);

      if (sailing.isDirty()) {
        const uploadedSailing = Sailing.clean(sailing);
        return sailingsCollection
          .doc(uploadedSailing.id!)
          .set(uploadedSailing.toObject())
          .then(() => {
            return uploadedSailing;
          });
      } else {
        return Promise.resolve(sailing);
      }
    });
  }

  public static getUserCollection(): firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  > {
    return firebase.firestore().collection(FirebaseUtils.usersCollectionName);
  }

  public static getSailingCollection(): firebase.firestore.CollectionReference<
    firebase.firestore.DocumentData
  > {
    return firebase
      .firestore()
      .collection(FirebaseUtils.sailingsCollectionName);
  }

  public static logClientData(obj: object): Promise<any> {
    const logClientData = firebase.functions().httpsCallable("logClientData");
    return logClientData(obj)
  }

  public static auth = auth;
}
