import { Port, DelayReason } from ".";
import { DateTime } from "luxon";
import lodash from "lodash";
import { Sailing } from "./Sailing";
export class Route {
  public static Empty = new Route(-1, "", [], []);

  public findMissedSailings(sailings: Sailing[]): DateTime[] {
    const sailingDepartureTimes = sailings
      .filter(sailing => !sailing.manifest.isScheduleDisabled())
      .map(sailing => sailing.manifest.scheduledDeparture!.toMillis());
    const scheduleDateTimes = Array.from(this._schedule.keys()).map(timeslot => timeslot.toMillis());
    const now = DateTime.local().toMillis();
    const dateTimesInPast = lodash.filter(scheduleDateTimes, time => {
      return time < now;
    });
    return lodash.difference(dateTimesInPast, sailingDepartureTimes).map(milliseconds => DateTime.fromMillis(milliseconds));
  }

  public static fromObject(jsonRouteObject: any): Route {
    return new Route(
      jsonRouteObject.id,
      jsonRouteObject.name,
      jsonRouteObject.ports.map(Port.fromObject),
      jsonRouteObject.delayReasons
    );
  }

  private _schedule: Map<DateTime, Port>;

  constructor(
    readonly id: number,
    readonly name: string,
    readonly ports: Port[],
    readonly delayReasons: DelayReason[]
  ) {
    this._schedule = this.buildSchedule(ports);
  }

  public get isEmpty(): boolean {
    return this.id === -1;
  }

  public get departureCount(): number {
    return this._schedule.size
  }

  public getNextDestination(port: Port): Port {
    const currentPortIndex = this.ports.findIndex(
      (myPort) => myPort.id === port.id
    );
    if (currentPortIndex < 0) {
      throw new Error("The port provided isn't part of the route");
    }

    return this.ports[(currentPortIndex + 1) % this.ports.length];
  }

  public getDestinationPortFromTime(time: DateTime): Port {
    return (
      Array.from(this._schedule.entries()).find((entry) => {
        const [dateTime] = entry;
        return dateTime > time;
      })?.[1] ?? this.ports[0]
    );
  }

  public toObject(): Object {
    return {
      id: this.id,
      name: this.name,
      ports: this.ports.map((port) => port.toObject()),
      delayReasons: this.delayReasons,
    };
  }

  private buildSchedule(ports: Port[]): Map<DateTime, Port> {
    class KeyValue {
      constructor(readonly key: DateTime, readonly value: Port) {}
    }

    const keyValues: KeyValue[] = ports.flatMap((port) => {
      return port.departures.map((departure) => new KeyValue(departure.toDateTime(), port));
    });

    // todo: make this functional. don't add stuff to the forEach!!!
    function toMap(keyValues: KeyValue[]) {
      let newMap = new Map<DateTime, Port>();
      lodash
        .sortBy(keyValues, function (o) {
          return o.key;
        })
        .forEach((keyValue) => {
          newMap.set(keyValue.key, keyValue.value);
        });
      return newMap;
    }

    return toMap(keyValues);
  }
}
