import * as React from "react";
import { createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { withRouter } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  ListTwoTone,
  ExitToAppTwoTone,
  CompareArrowsTwoTone,
  HomeTwoTone,
} from "@material-ui/icons";
import { AppRoutes } from "../data";
import packageJson from "../../package.json";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      bottom: 0,
      position: "absolute",
      width: 250,
    },
    list: {
      height: "100%",
      position: "relative",
      width: 250,
    },
  });

interface IMenuDrawerPropsAdmin extends WithStyles<typeof styles> {
  open: boolean;
  history?: any; // react's withRouter needs this as a type hint
  onDismiss: () => any;
  onSignOut: () => Promise<void>;
}

export class MenuDrawerAdmin extends React.Component<
  IMenuDrawerPropsAdmin,
  any
> {
  public render() {
    const classes = this.props.classes;
    return (
      <Drawer
        anchor="right"
        open={this.props.open}
        onClose={this.handleDismiss}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={this.handleDismiss}
          onKeyDown={this.handleDismiss}
          className={classes.list}
        >
          <List>
            <ListItem
              button={true}
              key="dashboard"
              onClick={this.handleDashboardClick}
            >
              <ListItemIcon>
                <ListTwoTone />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                secondary="View Today's Sailing details"
              />
            </ListItem>
            <ListItem
              button={true}
              key="reports"
              onClick={this.handleRoutesClick}
            >
              <ListItemIcon>
                <ListTwoTone />
              </ListItemIcon>
              <ListItemText
                primary="Generate Reports"
                secondary="View reports for a time period"
              />
            </ListItem>
            <Divider />
            <ListItem button={true} key="manage-routes" disabled={true}>
              <ListItemIcon>
                <CompareArrowsTwoTone />
              </ListItemIcon>
              <ListItemText primary="Manage Routes" secondary="Edit routes" />
            </ListItem>
            <ListItem button={true} key="manage-offices" disabled={true}>
              <ListItemIcon>
                <HomeTwoTone />
              </ListItemIcon>
              <ListItemText
                primary="Manage Offices"
                secondary="Assign routes, etc."
              />
            </ListItem>
          </List>
          <div className={classes.footer}>
            <List>
              <ListItem>Version: {packageJson.version}</ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                button={true}
                key="Sign Out"
                onClick={this.handleSignOut}
              >
                <ListItemIcon>
                  <ExitToAppTwoTone />
                </ListItemIcon>
                <ListItemText primary="SignOut" />
              </ListItem>
            </List>
          </div>
        </div>
      </Drawer>
    );
  }

  private handleDismiss = () => {
    this.props.onDismiss();
  };

  private handleSignOut = () => {
    this.props.onSignOut();
  };

  private handleRoutesClick = () => {
    this.props.history.push(AppRoutes.Reports);
  };

  private handleDashboardClick = () => {
    this.props.history.push(AppRoutes.Root);
  };
}

export default withStyles(styles)(withRouter<any, any>(MenuDrawerAdmin));
