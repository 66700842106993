import * as React from "react";
import withRoot from "./withRoot";
import { withRouter } from "react-router";
import {
  mdiTruckTrailer,
  mdiCar,
  mdiWalk,
  mdiBus,
  mdiMotorbike,
  mdiWheelchairAccessibility,
  mdiWorker,
  mdiDumpTruck,
} from "@mdi/js";
import { Route as ReactRoute, Switch, Redirect } from "react-router-dom";
import NavBar from "./components/NavBar";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import {
  WithNotifications,
  FirebaseUtils,
  AppUtils,
  LocalStoragePoller,
  LocalStorageUtils,
  FirestorePoller,
} from "./util";
import {
  LocalStorage,
  AppRoutes,
  Manifest as ManifestData,
  Route,
  DelayReason,
  Port,
  CargoView as Cargo,
  Sailing,
  User,
  Roles,
} from "./data";
import { withSnackbar } from "notistack";
import Manifest from "./components/manifest/Manifest";
import update from "immutability-helper";
import Sailings from "./components/Sailings";
import ServiceWorker from "./serviceWorker";
import Reports from "./components/reports/Reports";
import { DateTime } from "luxon";
import * as firebase from "firebase/app";
import { Loading } from "./components/Loading";
import Dashboard from "./components/Dashboard";
import ManifestEdit from "./components/manifest/ManifestEdit";
import { SailingDateType } from "./util/SailingDateType";
import CsvReport from "./components/reports/CsvReport";
import { Time } from "./data/Time";

interface IAppContainerProps extends WithNotifications {
  location?: any;
  history?: any; // react's withRouter needs this as a type hint
}

interface IAppContainerState {
  version: string;
  lastSailings: Sailing[];
  missedSailings: DateTime[];
  user: User;
  sailings: Sailing[];
  dashboardSailings: Sailing[];
  clearSailingData: boolean;
  loadingDashboardSailings: boolean;
  crew: number;
  supernumary: number;
}

class App extends React.Component<IAppContainerProps, IAppContainerState> {
  private intervalMs: number = 5000; // 5 seconds
  private intervalAdminMs: number = 300000; // 5 minutes
  private poller?: LocalStoragePoller;
  private firestorePoller?: FirestorePoller;
  private userStore: firebase.firestore.CollectionReference;
  private localStorageUtils: LocalStorageUtils;

  constructor(props: IAppContainerProps) {
    super(props);
    this.localStorageUtils = new LocalStorageUtils();

    const crew = this.localStorageUtils.get(LocalStorage.Crew) ?? 0;
    const supernumary =
      this.localStorageUtils.get(LocalStorage.Supernumary) ?? 0;

    const sailings: Sailing[] = this.localStorageUtils.get(
      LocalStorage.Sailings
    );

    const user = User.Unknown;
    this.state = {
      user: user,
      sailings: sailings,
      dashboardSailings: [],
      version: "",
      lastSailings: [],
      missedSailings: [],
      clearSailingData: false,
      loadingDashboardSailings: false,
      crew: crew,
      supernumary: supernumary,
    };

    this.userStore = FirebaseUtils.getUserCollection();
  }

  private firebaseStateUnsubscribe: any;

  public componentDidMount() {
    this.firebaseStateUnsubscribe = FirebaseUtils.auth().onAuthStateChanged(
      (firebaseUser) => {
        this.createUser(firebaseUser).then((user) => {
          this.setState({ user: user });

          if (user !== User.Anonymous) {
            FirebaseUtils.getPreviousSailings(user.route).then((sailings) => {
              this.localStorageUtils.set(LocalStorage.Sailings, sailings);
              this.setState({
                sailings: sailings,
                missedSailings: user.route.findMissedSailings(sailings),
              });
            });
          }

          if (user.isAdmin) {
            this.firestorePoller = new FirestorePoller(
              this.intervalAdminMs,
              this.generateStaticRoutes()[0],
              (route: Route, past: DateTime) =>
                FirebaseUtils.getSailings(
                  route.id,
                  past,
                  DateTime.local(),
                  SailingDateType.Confirmed
                ),
              this.handleSailingsCollectionUpdated
            );
            this.firestorePoller.start();
          }
        });
      }
    );

    this.poller = new LocalStoragePoller(
      this.intervalMs,
      this.getSailingsFromLocalStorage,
      this.updateLocalStorageWhenSailingsAreUploaded
    );

    if (this.state.sailings.length > 0) {
      this.poller!.start();
    }
  }

  private getSailingsFromLocalStorage = () => {
    return Promise.resolve(this.localStorageUtils.get(LocalStorage.Sailings));
  };

  private updateLocalStorageWhenSailingsAreUploaded = (sailings: Sailing[]) => {
    const oldCleanSailings = this.state.sailings.filter((sailing: Sailing) => {
      return !sailing.isDirty();
    });

    const newSailings = update(oldCleanSailings, { $push: sailings }).filter(
      (sailing) => !sailing.manifest.isDeleted
    );
    this.setState({
      sailings: newSailings,
      missedSailings: this.state.user.route.findMissedSailings(newSailings),
    });
    this.localStorageUtils.set(LocalStorage.Sailings, newSailings);
  };

  public componentWillUnmount() {
    this.firebaseStateUnsubscribe && this.firebaseStateUnsubscribe();
    if (this.poller) {
      this.poller!.stop();
    }
  }

  public render() {
    const { user, version, missedSailings } = this.state;
    if (user.role === Roles.Unknown) {
      return <Loading />;
    }

    const navJsx = (
      <NavBar
        user={user}
        onSignOut={this.handleSignout}
        onUploadState={this.handleUploadState}
        version={version}
        missedSailings={missedSailings}
      />
    );
    const serviceWorkerJsx = (
      <ServiceWorker
        onUpdate={this.handleAppUpdated}
        onLoad={this.handleSetVersion}
      />
    );
    const appRoutes = this.getAppRoutes(user.isAuthenticated, user.isAdmin);

    return (
      <>
        {navJsx}
        {appRoutes}
        {serviceWorkerJsx}
      </>
    );
  }

  private getAppRoutes(isAuthenticated: boolean, isAdmin: boolean) {
    if (!isAuthenticated) {
      return this.publicAppRoutes();
    } else if (isAdmin) {
      return this.adminAppRoutes();
    } else {
      return this.authedAppRoutes();
    }
  }

  private publicAppRoutes() {
    return (
      <Switch>
        <ReactRoute path={AppRoutes.SignUp} render={this.renderSignUp} />
        <ReactRoute render={this.renderSignin} />
      </Switch>
    );
  }

  private adminAppRoutes() {
    return (
      <Switch>
        <ReactRoute
          path={AppRoutes.SignOut}
          render={(props) => {
            this.handleSignout();
            return <Redirect to={AppRoutes.Root} />;
          }}
        />
        <ReactRoute
          path={`${AppRoutes.Reports}${AppRoutes.WaterbridgeReport}`}
          render={this.renderWaterbridgeReport}
        />
        <ReactRoute path={AppRoutes.Reports} render={this.renderReports} />
        <ReactRoute render={this.renderDashboard} />
      </Switch>
    );
  }

  private authedAppRoutes() {
    return (
      <Switch>
        <ReactRoute
          path={AppRoutes.SignOut}
          render={(props) => {
            this.handleSignout();
            return <Redirect to={AppRoutes.Root} />;
          }}
        />
        <ReactRoute
          exact
          path={AppRoutes.Sailings}
          render={this.renderSailings}
        />
        <ReactRoute
          path={AppRoutes.Manifest.concat(AppRoutes.WildcardId)}
          render={this.renderManifestEdit}
        />
        <ReactRoute render={this.renderManifest} />
      </Switch>
    );
  }

  private renderSignUp = (props: any) => {
    return <SignUp onSignup={this.handleSignup} />;
  };
  private renderSignin = (props: any) => {
    return <SignIn onSignIn={this.handleSignin} />;
  };

  private renderDashboard = (props: any) => {
    return (
      <Dashboard
        sailings={this.state.dashboardSailings}
        onDateChange={this.handleDateChange}
        loading={this.state.loadingDashboardSailings}
        cargoViews={this.generateCargoViews()}
      />
    );
  };

  private renderReports = (props: any) => {
    return <Reports routes={this.state.user.routes} />;
  };

  private renderWaterbridgeReport = (props: any) => {
    return <CsvReport cargoViews={this.generateCargoViews()} />;
  };

  private getDeparturesAlreadyLogged(): DateTime[] {
    return this.state.sailings
      .filter((sailing) => {
        return !sailing.manifest.isScheduleDisabled();
      })
      .map((sailing) => {
        return sailing.manifest.scheduledDeparture!;
      });
  }

  private renderManifest = (props: any) => {
    return (
      <Manifest
        route={this.state.user.route}
        previousDepartures={this.getDeparturesAlreadyLogged()}
        cargoViews={this.generateCargoViews()}
        onSubmit={this.handleManifestSubmit}
      />
    );
  };

  private renderManifestEdit = (props: any) => {
    const sailingId = props.match.params.id;
    const sailing = this.state.sailings.find(
      (sailing) => sailing.id === sailingId
    );

    return (
      <ManifestEdit
        route={this.state.user.route}
        previousDepartures={this.getDeparturesAlreadyLogged()}
        cargoViews={this.generateCargoViews()}
        onSubmit={this.handleManifestEditSubmit}
        sailingData={sailing}
      />
    );
  };

  private renderSailings = (props: any) => {
    return (
      <Sailings
        route={this.state.user.route}
        sailings={this.state.sailings}
        onEditSailing={this.handleEditSailing}
      />
    );
  };

  private handleSetVersion = (newVersion: string) => {
    this.setState({ version: newVersion });
  };

  private handleAppUpdated = (newVersion: string) => {
    this.props.enqueueSnackbar(
      `The app as been updated, please refresh to use latest version: ${newVersion}`
    );
    this.handleSetVersion(newVersion);
  };

  private handleSignup = (userName: string, password: string) =>
    FirebaseUtils.createUser(userName, password)
      .then((user) => {
        this.props.enqueueSnackbar("Account created!", { variant: "success" });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: "error" });
      });

  private handleSignin = (userName: string, password: string) =>
    FirebaseUtils.signIn(userName, password)
      .then((userCredential) => {
        this.createUser(userCredential.user).then((user) => {
          this.setState({ user: user });
          FirebaseUtils.getPreviousSailings(user.route).then((sailings) => {
            if (sailings.length > 0) {
              this.setState({
                lastSailings: sailings,
              });
            }
          });
        });
        this.props.enqueueSnackbar("Signed in!", { variant: "success" });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: "error" });
      });

  private createUser = (firebaseUser: firebase.User | null): Promise<User> => {
    if (firebaseUser) {
      const user = firebaseUser.getIdToken().then((token) => {
        return this.userStore
          .where("email", "==", firebaseUser.email)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length === 0) {
              return User.Anonymous;
            }
            const data = snapshot.docs.pop()!.data();
            const user = new User(
              token,
              firebaseUser.email!,
              data.role,
              this.generateStaticRoutes()
            );
            return user;
          });
      });
      return user;
    } else {
      return Promise.resolve(User.Anonymous);
    }
  };

  private handleUploadState = () => {
    const sailings = this.localStorageUtils.get(LocalStorage.Sailings);
    const results = sailings.map((sailing: Sailing) => {
      return FirebaseUtils.logClientData(sailing.toObject());
    });
    return Promise.all(results);
  };

  private handleSignout = () =>
    FirebaseUtils.signOut()
      .then((_) => {
        this.localStorageUtils.clear();
        this.props.enqueueSnackbar("Signed out.", { variant: "success" });
        return Promise.resolve();
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: "error" });
        return Promise.reject(error);
      });

  private handleManifestEditSubmit = (
    newManifest: ManifestData,
    _: number,
    sailingId?: string
  ) => {
    const sailings = this.state.sailings;
    const oldSailing = sailings.find((sailing) => sailing.id === sailingId);
    const newSailing = Sailing.update(oldSailing!, {
      manifest: { $set: newManifest },
    });

    const unalteredSailings = sailings.filter(
      (sailing) => sailing.id !== sailingId
    );
    const newSailings = update(unalteredSailings, { $push: [newSailing] });
    this.localStorageUtils.set(LocalStorage.Sailings, newSailings);
    if (!this.poller!.isStarted) {
      this.poller!.start();
    }
    this.setState({ sailings: newSailings });
    this.props.history!.push(AppRoutes.Sailings);
  };

  private handleEditSailing = (sailing: Sailing) => {
    this.props.history!.push(AppRoutes.Manifest.concat(`/${sailing.id}`));
  };

  private handleManifestSubmit = (
    manifest: ManifestData,
    confirmed: DateTime
  ) => {
    const sailing = AppUtils.createSailing(
      this.state.user.route,
      manifest,
      confirmed
    );
    const oldSailings: Sailing[] = this.localStorageUtils.get(
      LocalStorage.Sailings
    );
    const sailings: Sailing[] = update(oldSailings, { $push: [sailing] });
    this.localStorageUtils.set(LocalStorage.Sailings, sailings);
    if (!this.poller!.isStarted) {
      this.poller!.start();
    }
    this.setState({ sailings });

    const [crewCount, supernumaryCount] = manifest.crewCounts;
    this.localStorageUtils.set(LocalStorage.Crew, crewCount);
    this.localStorageUtils.set(LocalStorage.Supernumary, supernumaryCount);
    this.setState({
      crew: crewCount,
      supernumary: supernumaryCount,
    });

    FirebaseUtils.logClientData({
      message: "sailing created",
      sailing: sailing.toObject(),
      old: oldSailings.map((sailing) => sailing.toObject()),
      new: sailings.map((sailing) => sailing.toObject()),
    })
      .then((res) => {
        console.log("cloud function result", res);
      })
      .catch((error) => {
        debugger;
        console.error(error);
      });

    this.props.history!.push(AppRoutes.Manifest);
  };

  private handleSailingsCollectionUpdated = (sailings: Sailing[]) => {
    this.setState({ sailings });
  };

  private handleDateChange = (start: DateTime, end: DateTime) => {
    this.setState({ loadingDashboardSailings: true });
    FirebaseUtils.getSailings(
      this.state.user.route.id,
      start.startOf("day"),
      end.endOf("day"),
      SailingDateType.Scheduled
    ).then((sailings) => {
      this.setState({
        loadingDashboardSailings: false,
        dashboardSailings: sailings,
      });
    });
  };

  private generateStaticRoutes = () => {
    const delays: DelayReason[] = [
      new DelayReason(1, "Traffic Delay"),
      new DelayReason(2, "Weather Conditions"),
      new DelayReason(3, "Environmental Conditions"),
      new DelayReason(4, "Medical Emergency"),
      new DelayReason(5, "Safety Drills"),
      new DelayReason(6, "Operational Delay"),
      new DelayReason(7, "Maintenance"),
      new DelayReason(8, "Mechanical Issue"),
      new DelayReason(9, "Safety Inspection"),
      new DelayReason(10, "Fueling"),
    ];

    const southSidePort = new Port(
      2,
      "Southside",
      [
        new Time(5, 30),
        new Time(6, 20),
        new Time(7, 10),
        new Time(8, 0),
        new Time(8, 50),
        new Time(9, 40),
        new Time(10, 30),
        new Time(11, 30),
        new Time(12, 30),
        new Time(13, 30),
        new Time(14, 30),
        new Time(15, 20),
        new Time(16, 10),
        new Time(17, 0),
        new Time(17, 50),
        new Time(18, 40),
        new Time(19, 30),
        new Time(20, 30),
        new Time(21, 30),
        new Time(22, 30),
      ],
      1
    );

    const northSidePort = new Port(
      1,
      "Northside",
      [
        new Time(5, 55),
        new Time(6, 45),
        new Time(7, 35),
        new Time(8, 25),
        new Time(9, 15),
        new Time(10, 5),
        new Time(11, 0),
        new Time(12, 1),
        new Time(13, 0),
        new Time(14, 0),
        new Time(14, 55),
        new Time(15, 45),
        new Time(16, 35),
        new Time(17, 30),
        new Time(18, 15),
        new Time(19, 5),
        new Time(20, 0),
        new Time(21, 0),
        new Time(22, 0),
        new Time(23, 0),
      ],
      2,
      19
    );

    return [
      new Route(1, "Francois Lake", [northSidePort, southSidePort], delays),
    ];
  };

  private generateCargoViews = () =>
    [
      new Cargo(Cargo.PASSENGER, "Total passengers on board", mdiWalk, 0, 0),
      new Cargo(2, "Cars / Pick Ups", mdiCar, 0, 1),
      new Cargo(3, "Commercial under 12m", mdiDumpTruck, 0, 2),
      new Cargo(4, "Commercial over 12m", "ChipTruck", 0, 3),
      new Cargo(Cargo.CHIP_TRUCK, "Chip Trucks", "ChipTruck", 0, 4),
      new Cargo(
        Cargo.LOGGING_TRUCK,
        "Loaded Logging Trucks",
        "LoadedLogging",
        0,
        5
      ),
      new Cargo(
        Cargo.LUMBER_TRUCK,
        "Loaded Lumber Trunks",
        "LoadedLumber",
        0,
        6
      ),
      new Cargo(Cargo.SEMI_OVER_24, "Semi over 24m", "SemiOver24", 0, 7),
      new Cargo(8, "RV under 6m", "RvUnder6", 0, 8),
      new Cargo(9, "RVs over 6m", "RvOver6", 0, 9),
      new Cargo(10, "Bus", mdiBus, 0, 10),
      new Cargo(Cargo.TRAILER, "Trailer / Tow-on", mdiTruckTrailer, 0, 11),
      new Cargo(12, "Motorcycles", mdiMotorbike, 0, 12),
      new Cargo(Cargo.PRA, "P.R.A.", mdiWheelchairAccessibility, 0, 13),
      new Cargo(Cargo.OVERFLOW, "Overflow", mdiCar, 0, 14),
      new Cargo(Cargo.CREW, "Crew", mdiWorker, this.state.crew, -2, true),
      new Cargo(
        Cargo.SUPERNUMARY,
        "Supernumary",
        mdiWorker,
        this.state.supernumary,
        -1,
        true
      ),
    ].sort((a, b) => a.order - b.order);
}

export default withSnackbar(withRoot(withRouter<any, any>(App)));
