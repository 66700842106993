import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing(),
    },
    dense: {
      marginTop: 16,
    },
    form: {
      marginTop: theme.spacing(),
      width: "100%", // Fix IE 11 issue.
    },
    layout: {
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
      [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 400,
      },
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(8),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
        3
      )}px`,
    },
    submit: {
      marginTop: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
    link: {
      color: theme.palette.secondary.main,
    },
    header: {
      color: "#ffffff",
    },
  });

interface ISignInProps extends WithStyles<typeof styles> {
  onSignIn: (userName: string, password: string) => void;
}

interface ISignInState {
  userName?: string;
  password?: string;
}

class SignIn extends React.Component<ISignInProps, ISignInState> {
  constructor(props: ISignInProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const classes = this.props.classes;
    const isValid =
      !this.state.userName ||
      !this.state.password ||
      this.state.userName === "" ||
      this.state.password === "";

    return (
      <form>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className={classes.header}>
              Sign In
            </Typography>
            <TextField
              id="outlined-email-input"
              label="Email"
              className={classes.textField}
              type="email"
              name="email"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              required={true}
              fullWidth={true}
              onChange={this.handleUserNameChanged}
            />
            <TextField
              id="outlined-password-input"
              label="Password"
              className={classes.textField}
              type="password"
              autoComplete="off"
              margin="normal"
              variant="outlined"
              required={true}
              fullWidth={true}
              onChange={this.handlePasswordChanged}
            />
            <Button
              type="submit"
              size="large"
              fullWidth={true}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isValid}
              onClick={this.handleSubmitForm}
            >
              Sign In
            </Button>
            <br />
          </Paper>
        </main>
      </form>
    );
  }

  private handleSubmitForm = (event: any) => {
    event.preventDefault();
    this.props.onSignIn(this.state.userName!, this.state.password!);
  };

  private handleUserNameChanged = (event: any) => {
    this.setState({ userName: event.target.value });
  };

  private handlePasswordChanged = (event: any) => {
    this.setState({ password: event.target.value });
  };
}

export default withStyles(styles)(SignIn);
