import * as React from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { DateTime } from "luxon";
import { Paper, Grid, Button } from "@material-ui/core";
import StartEndDatePicker from "../StartEndDatePicker";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CargoView, ReportGenerator, ReportRow } from "../../data";
import { FirebaseUtils } from "../../util";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(),
      padding: theme.spacing(),
      overflowX: "auto",
    },
    table: {
      width: "100%",
    },
    headerRow: {
      whiteSpace: "nowrap",
      verticalAlign: "bottom",
    },
    headerCell: {
      height: "240px",
      whiteSpace: "nowrap",
      border: "1px solid rgba(81, 81, 81, 1)",
      padding: "0",
      paddingBottom: theme.spacing(2),
    },
    headerDiv: {
      transform: "rotate(270deg)",
      width: "30px",
    },
    bodyCell: {
      textAlign: "center",
      whiteSpace: "nowrap",
      border: "1px solid rgba(81, 81, 81, 1)",
      padding: "0",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  });

export interface ICsvReportProps extends WithStyles<typeof styles> {
  cargoViews: CargoView[];
}

export interface ICsvReportState {
  report: ReportRow[];
}

class CsvReport extends React.Component<ICsvReportProps, ICsvReportState> {
  private reportGenerator: ReportGenerator;

  public constructor(props: ICsvReportProps) {
    super(props);

    const sailingsCollection = FirebaseUtils.getSailingCollection();
    this.reportGenerator = new ReportGenerator(sailingsCollection);

    this.state = {
      report: [],
    };
  }

  public componentDidMount() {
    this.handleGenerateReport(
      DateTime.local().startOf("month"),
      DateTime.local().endOf("day")
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <h2>Waterbridge CSV Report</h2>
          </Grid>
          <Grid item>
            <Button
              onClick={this.handleDownloadClick}
              variant="contained"
              color="primary"
            >
              Export
            </Button>
          </Grid>
        </Grid>
        <Paper className={classes.paper} elevation={1}>
          <StartEndDatePicker
            start={DateTime.local().startOf("month")}
            onDateRangeSubmit={this.handleGenerateReport}
          />
        </Paper>

        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Date</span>
                </div>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Leg</span>
                </div>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Trips</span>
                </div>
              </TableCell>
              {this.props.cargoViews
                .filter((view) => view.id !== CargoView.SUPERNUMARY)
                .map((view) => {
                  return (
                    <TableCell key={view.id} className={classes.headerCell}>
                      <div className={classes.headerDiv}>
                        <span>{view.label}</span>
                      </div>
                    </TableCell>
                  );
                })}
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Overflow Trips</span>
                </div>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Trailer Trucks</span>
                </div>
              </TableCell>
              <TableCell className={classes.headerCell}>
                <div className={classes.headerDiv}>
                  <span>Vehicles less Trailers</span>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.report.map((reportRow) => {
              return (
                <TableRow
                  key={`${reportRow.scheduled}-${reportRow.destinationPortId}`}
                >
                  <TableCell className={classes.bodyCell}>
                    <div style={{ width: 90 }}>
                      {reportRow.scheduled === Number.MIN_SAFE_INTEGER
                        ? "Total"
                        : DateTime.fromMillis(
                            reportRow.scheduled
                          ).toLocaleString(DateTime.DATE_SHORT)}
                    </div>
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    {reportRow.destinationPortId === Number.MIN_SAFE_INTEGER
                      ? "n/a"
                      : reportRow.destinationPortId}
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    {reportRow.tripCount}
                  </TableCell>
                  {this.generateTableCellsForCargo(reportRow.cargoCount)}
                  <TableCell className={classes.bodyCell}>
                    {reportRow.overFlowTrips}
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    {reportRow.trailerTrucks}
                  </TableCell>
                  <TableCell className={classes.bodyCell}>
                    {reportRow.vehiclesNoTrailers}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  private generateTableCellsForCargo = (cargoMap: Map<number, number>) => {
    const { classes } = this.props;
    return this.props.cargoViews
      .filter((view) => view.id !== CargoView.SUPERNUMARY)
      .map((view) => {
        const cargo =
          cargoMap.get(view.id) === Number.MIN_SAFE_INTEGER
            ? "n/a"
            : cargoMap.get(view.id);
        return (
          <TableCell key={view.id} className={classes.bodyCell}>
            {cargo ?? 0}
          </TableCell>
        );
      });
  };

  private handleGenerateReport = (start: DateTime, end: DateTime) => {
    this.reportGenerator.getData(start, end).then((data) => {
      this.setState({ report: data });
    });
  };

  private handleDownloadClick = () => {
    const csv = this.reportGenerator.prepareCsv(
      this.state.report,
      this.props.cargoViews
    );
    const encodedUri = encodeURI(csv);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "waterbridge_export.csv");
    link.click();
  };
}

export default withStyles(styles)(CsvReport);
