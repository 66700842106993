import { Manifest, Route, Sailing, Cargo, CargoView } from "../data";
import { DateTime } from "luxon";

export class AppUtils {
  public static readonly PassengerIds = [
    CargoView.PASSENGER,
    CargoView.PRA,
    CargoView.SUPERNUMARY,
    CargoView.CREW,
  ];

  public static createSailing = (
    route: Route,
    manifest: Manifest,
    confirmed: DateTime
  ) => {
    const cargo: Cargo[] = manifest.cargo.filter((cargo) => {
      return cargo.count > 0;
    });

    const newManifest = new Manifest(
      cargo,
      manifest.departurePort,
      manifest.isShuttling,
      manifest.isEmergency,
      manifest.departure,
      manifest.scheduledDeparture,
      manifest.delayReasonId
    );

    return new Sailing(route, newManifest, confirmed);
  };

  public static formatTime(time?: DateTime) {
    const result = time?.toLocaleString(DateTime.TIME_24_SIMPLE) ?? "n/a";
    return result;
  }

  public static formatDateTime(time?: DateTime) {
    return time?.toLocaleString(DateTime.DATETIME_SHORT) ?? "n/a";
  }

  public static sailingSort = (sailing1: Sailing, sailing2: Sailing) => {
    return sailing1.manifest.scheduledDeparture!.toMillis() - sailing2.manifest.scheduledDeparture!.toMillis();
  };

  public static isLocalhost(): boolean {
    return Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
  }

  public static useStaging(): boolean {
    const isLocalhost = AppUtils.isLocalhost();
    const isStaging = window.location.hostname.includes("staging");
    return isLocalhost || isStaging;
  }

  public static unimplemented() {
    throw new Error("this feature isn't implemented");
  }
}

/**
 * Helper interface for using inotistack with typescript
 */
export interface WithNotifications {
  enqueueSnackbar: (message: string, options?: any) => void;
}
